import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import CheckCircleIcon from '../layout/icons/actions/CheckCircleIcon'
import ShoppingBasketIcon from '../layout/icons/navigation/ShoppingBasketIcon'
import HomeIcon from '../layout/icons/navigation/HomeIcon'

const Success = ({ setIsSuccessOpen }) => {

    const { user } = useSelector( state => state.auth ) 

    const handleClose = () => {
        setIsSuccessOpen(false)
    }

    return (

        <div className="wrapper">

            <h2>Order Placed!</h2>
            
            <p style={{ color: 'var(--paragraph-text-color)' }}>You may now close this tab.</p>

            <div 
                className="text-center"
                style={{ pointerEvents: 'none', margin: '80px auto' }}
            >
                <CheckCircleIcon style={{ fontSize: '5rem', color: 'var(--cta-green)' }} />    
            </div>

            <div className="d-flex gap-4 justify-content-center">

               {user && (
                    <span>
                        <Link to="/orders/me" onClick={handleClose}>
                            <button className="icon-button" style={{ margin: '0 auto' }}>
                                <ShoppingBasketIcon />
                            </button>
                            <br />
                            <h4>My Orders</h4>
                        </Link>                    
                    </span>
               )}

                <span>
                    <Link to="/" onClick={handleClose}>
                        <button className="icon-button" style={{ margin: '0 auto' }}>
                            <HomeIcon />
                        </button>
                        <br />
                        <h4>Home</h4>
                    </Link>                    
                </span>

            </div>

        </div>

    )

}

export default Success