import { useState, useEffect } from 'react'
import FormatColorResetIcon from '../icons/palette/FormatColorResetIcon'
import WaterDropIcon from '../icons/palette/WaterDropIcon'
import ToolTip from '../tools/ToolTip'

const ColorPicker = () => {    

    const grey = '#999999'
    const greyDark = '#777777'

    const gold = '#88744a'
    const goldDark = '#72613e'

    const yellow = '#f9be2a'
    const yellowDark = 'goldenRod'

    const orange = '#fd961a'
    const orangeDark = '#b36910'

    const green = '#8ad400'
    const greenDark = '#69a200'

    const trueGreen = '#4caf50'
    const trueGreenDark = '#43a047'

    const blue = '#00ccff'
    const blueDark = '#01add8'

    const pastelBlue = '#5783db'
    const pastelBlueDark = '#4c73c1'   

    const navy = '#06f'
    const navyDark = '#0066CC'

    const [ _colorOne, setColorOne ] = useState('')    
    
    useEffect(() => {
        let col1 = localStorage.getItem('colorOne') 
        let col2 = localStorage.getItem('colorTwo') 
        if (col1 && col2) {            
            switchColor(col1, col2)     
            setColorOne(col1)       
        } else {
            setColorOne(navy)
        }
    }, [])
    
    const switchColor = (colorOne, colorTwo) => {
        document.documentElement.style
            .setProperty('--primary-color', colorOne)
        document.documentElement.style
            .setProperty('--primary-color-dark', colorTwo)
        localStorage.setItem('colorOne', colorOne)
        localStorage.setItem('colorTwo', colorTwo)
        setColorOne(colorOne)
    }

    return (
        <>
        <div className="d-flex"  style={{ gap: 10, marginBottom: 10 }}>

            <ToolTip title="Grey">
                <button 
                    className="icon-button" 
                    aria-label="Grey color picker"
                    onClick={() => switchColor(grey, greyDark)}   
                    style={{ pointerEvents: _colorOne === grey ? 'none' : 'auto' }}         
                >
                    {_colorOne === grey 
                        ? <FormatColorResetIcon style={{ color: grey }}  /> 
                        : <WaterDropIcon style={{ color: grey }} />
                    }                 
                </button>
            </ToolTip>
            
        </div>

        <div className="d-flex"  style={{ gap: '10px' }}>   

            <ToolTip title="Orange">
                <button 
                    className="icon-button" 
                    aria-label="Orange color picker"
                    onClick={() => switchColor(orange, orangeDark)}  
                    style={{ pointerEvents: _colorOne === orange ? 'none' : 'auto' }}          
                >
                    {_colorOne === orange 
                        ? <FormatColorResetIcon style={{ color: orange }} /> 
                        : <WaterDropIcon style={{ color: orange }} />
                    } 
                </button>
            </ToolTip>                  

            <ToolTip title="Yellow">
                <button 
                    className="icon-button" 
                    aria-label="Yellow color picker"
                    onClick={() => switchColor(yellow, yellowDark)}     
                    style={{ pointerEvents: _colorOne === yellow ? 'none' : 'auto' }}       
                >
                    {_colorOne === yellow 
                        ? <FormatColorResetIcon style={{ color: yellow }} />
                        : <WaterDropIcon style={{ color: yellow }} /> 
                    } 
                </button>   
            </ToolTip>  

            <ToolTip title="Gold">
                <button 
                    className="icon-button" 
                    aria-label="Gold color picker"
                    onClick={() => switchColor(gold, goldDark)}     
                    style={{ pointerEvents: _colorOne === gold ? 'none' : 'auto' }}       
                >
                    {_colorOne === gold 
                        ? <FormatColorResetIcon style={{ color: gold }} />
                        : <WaterDropIcon style={{ color: gold }} /> 
                    } 
                </button>   
            </ToolTip>  
 
        </div>

        <div className="d-flex"  style={{ gap: '10px' }}>   
            <ToolTip title="Blue">
                <button 
                    className="icon-button" 
                    aria-label="Blue color picker"
                    onClick={() => switchColor(blue, blueDark)}     
                    style={{ pointerEvents: _colorOne === blue ? 'none' : 'auto' }}       
                >
                    {_colorOne === blue 
                        ? <FormatColorResetIcon style={{ color: blue }} />
                        : <WaterDropIcon style={{ color: blue }} /> 
                    } 
                </button>   
            </ToolTip>  
            <ToolTip title="Pastel Blue">
                <button 
                    className="icon-button" 
                    aria-label="pastelBlue color picker"
                    onClick={() => switchColor(pastelBlue, pastelBlueDark)}     
                    style={{ pointerEvents: _colorOne === pastelBlue ? 'none' : 'auto' }}       
                >
                    {_colorOne === pastelBlue 
                        ? <FormatColorResetIcon style={{ color: pastelBlue }} />
                        : <WaterDropIcon style={{ color: pastelBlue }} /> 
                    } 
                </button>   
            </ToolTip>    
            <ToolTip title="Navy">
                <button 
                    className="icon-button" 
                    aria-label="navy color picker"
                    onClick={() => switchColor(navy, navyDark)}     
                    style={{ pointerEvents: _colorOne === navy ? 'none' : 'auto' }}       
                >
                    {_colorOne === navy 
                        ? <FormatColorResetIcon style={{ color: navy }} />
                        : <WaterDropIcon style={{ color: navy }} /> 
                    } 
                </button>   
            </ToolTip>
        </div>

        <div className="d-flex"  style={{ gap: '10px' }}> 

            <ToolTip title="Green">
                <button 
                    className="icon-button" 
                    aria-label="Green color picker"
                    onClick={() => switchColor(green, greenDark)}     
                    style={{ pointerEvents: _colorOne === green ? 'none' : 'auto' }}       
                >
                    {_colorOne === green 
                        ? <FormatColorResetIcon style={{ color: green }} />
                        : <WaterDropIcon style={{ color: green }} /> 
                    } 
                </button>   
            </ToolTip>      

            <ToolTip title="True Green">
                <button 
                    className="icon-button" 
                    aria-label="trueGreen color picker"
                    onClick={() => switchColor(trueGreen, trueGreenDark)}     
                    style={{ pointerEvents: _colorOne === trueGreen ? 'none' : 'auto' }}       
                >
                    {_colorOne === trueGreen 
                        ? <FormatColorResetIcon style={{ color: trueGreen }} />
                        : <WaterDropIcon style={{ color: trueGreen }} /> 
                    } 
                </button>   
            </ToolTip>   

        </div>
        </>
    )

}

export default ColorPicker