import { useEffect, useRef, useState                       } from 'react'
import { useMediaQuery                                     } from 'react-responsive'
import { TransformComponent, TransformWrapper              } from 'react-zoom-pan-pinch'
import { LazyLoadImage                                     } from 'react-lazy-load-image-component'
import { cloudinaryURL, handleTouchEndX, handleTouchStartX } from '../../../utils'
import IconButton                                            from '../../layout/tools/iconButton/IconButton'
import KeyboardArrowLeftIcon                                 from '../../layout/icons/arrows/KeyboardArrowLeftIcon'
import KeyboardArrowRightIcon                                from '../../layout/icons/arrows/KeyboardArrowRightIcon'
import ZoomInMapIcon                                         from '../../layout/icons/zoom/ZoomInMapIcon'
import ZoomInIcon                                            from '../../layout/icons/zoom/ZoomInIcon'
import ZoomOutIcon                                           from '../../layout/icons/zoom/ZoomOutIcon'
import PlayArrowIcon                                         from '../../layout/icons/play/PlayArrowIcon'
import PauseIcon                                             from '../../layout/icons/play/PauseIcon'
import StopIcon                                              from '../../layout/icons/play/StopIcon'
import ClearIcon                                             from '../../layout/icons/actions/ClearIcon'
import DockAtBottomIcon                                      from '../../layout/icons/layout/DockAtBottomIcon'
import DockOnRightIcon                                       from '../../layout/icons/layout/DockOnRightIcon'
import CircularProgress                                      from '../../layout/icons/circularProgress/CircularProgress'
import CircularLoader                                        from '../../layout/icons/circularLoader/CircularLoader'
import Thumbnails                                            from './Thumbnails'
import 'react-lazy-load-image-component/src/effects/blur.css'
import './lightbox.css'

const Lightbox = ({ images, altTag, currentIndex, setCurrentIndex, setIsLightboxOpen, timing=8000 }) => {

    const sliderRef = useRef(null)    
    const startXRef = useRef(0)

    const isLandscape = useMediaQuery({ query: '(orientation: landscape)' })

    const [ shouldMatchSlide, setShouldMatchSlide ] = useState(true)
    const [ playState,        setPlayState        ] = useState('stop')
    const [ isDockedRight,    setIsDockedRight    ] = useState(isLandscape)  
    const [ zoomScale,        setZoomScale        ] = useState(1)
    const [ loadingStates,    setLoadingStates    ] = useState(Array(images.length).fill(true))        

    useEffect(() => {   
        document.body.style.overflow = 'hidden'
        const handleTabChange = () => {
            if (document.hidden) setPlayState('stop')
        } 
        const slider = sliderRef.current
        const targetOne = document.querySelector('.lightbox-arrow:last-of-type')
        const targetTwo = document.querySelector('.lightbox-arrow')
        const handleStart = (e) => {              
            if (zoomScale === 1) handleTouchStartX(e, startXRef)
        }    
        const handleEnd = (e) => {
            if (zoomScale === 1) handleTouchEndX(e, startXRef, targetOne, targetTwo)
        }
        slider.addEventListener('touchstart', handleStart)
        slider.addEventListener('touchend', handleEnd)   
        slider.addEventListener('mousedown', handleStart)
        slider.addEventListener('mouseup', handleEnd)
        document.addEventListener('visibilitychange', handleTabChange)    
        return () => {            
            slider.removeEventListener('touchstart', handleStart)
            slider.removeEventListener('touchend', handleEnd)
            slider.removeEventListener('mousedown', handleStart)
            slider.removeEventListener('mouseup', handleEnd)
            document.removeEventListener('visibilitychange', handleTabChange)
        }
    }, [currentIndex, isLandscape, playState, zoomScale])

    useEffect(() => {  
        if (playState !== 'playing') return
        const target = document.querySelector('.lightbox-arrow:last-of-type')
        const intervalId = setInterval(() => target.click(), timing)
        return () => clearInterval(intervalId)
    }, [playState, timing]) 

    const handleImageLoad = (i) => {
        setLoadingStates((prevStates) => {
            const newStates = [...prevStates]
            newStates[i] = false
            return newStates
        })
    } 
    
    const handleThumb = (i) => { 
        setCurrentIndex(i)  
        setShouldMatchSlide(true)
    }   

    const handleTransform = (e) => {
        const scale = e.instance.transformState.scale
        const xPosition = e.instance.transformState.positionX
        setZoomScale(scale || 1)    
        if (xPosition !== 0 && playState === 'playing' && scale === 1) {
            setPlayState('paused')
        }    
    } 

    const handlePlayState = () => {  
        handleReset(currentIndex)  
        if (playState === 'stop') {
            setPlayState('playing')             
        } else if (playState === 'paused') {
            setPlayState('playing') 
            handleRotation('right')
        } else {
            setPlayState('paused') 
        }       
    }

    const handleStop = () => {
        setPlayState('stop')
        handleReset(currentIndex)
    }

    const handleReset = (i) => {          
        const target = document.querySelectorAll('.lightbox-reset')[i]
        target.click()
    }
    
    const handleRotation = (dir) => {  
        setShouldMatchSlide(true)    
        handleReset(currentIndex)
        setCurrentIndex(dir === 'right' 
            ? (currentIndex + 1) % images.length
            : (currentIndex - 1  + images.length) % images.length)             
    } 

    const handleIsDocked = () => {
        setIsDockedRight(!isDockedRight)
        setShouldMatchSlide(true)
    }

    const handleClose = () => {
        setIsLightboxOpen(false)
        document.body.style.overflow = 'auto'                                            
        if (document.fullscreenElement) document.exitFullscreen() 
    }

    return (
       
        <div 
            className={`
                lightbox-container 
                ${isDockedRight ? 'lightbox-portrait' : 'lightbox-landscape'}
                ${playState === 'playing' 
                    ? 'lightbox-playing' 
                    : playState === 'paused'
                        ? 'lightbox-paused'
                        : ''
                }`
            }
        >  

            <div className="lightbox-stage" ref={sliderRef}>  

                {images.map((image, index) => (

                    <TransformWrapper 
                        key={index}
                        panning={{ lockAxisY: zoomScale === 1 }} 
                        initialScale={1}
                        onTransformed={(e) => handleTransform(e)}  
                        onZoomStart={() => setPlayState(playState === 'playing' ? 'paused' : playState === 'paused' ? 'paused' : 'stop')}                 
                    >                  

                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                            <>
                            <div className={`lightbox-header ${index === currentIndex ? 'active' : ''}`}>
                                
                                <div className="lightbox-title">
                                    <small>{currentIndex + 1} / {images.length}</small>
                                    {(image.title || altTag) && <i />}
                                    <small>{image.title || altTag}</small>
                                    <small>{zoomScale > 1 && `x${zoomScale.toFixed(1)}`}</small>                                    
                                </div>

                                <div className="lightbox-icons">  
                                    <>
                                    <IconButton
                                        aria="zoom in"
                                        toolTip="zoom in"
                                        icon={<ZoomInIcon />}
                                        onClick={() => zoomIn()}
                                    />
                                    <IconButton
                                        aria="zoom out"
                                        toolTip="zoom out"
                                        icon={<ZoomOutIcon />}
                                        onClick={() => zoomOut()}
                                    />
                                    </>

                                    <IconButton
                                        aria="reset zoom"
                                        toolTip="reset zoom"
                                        icon={<ZoomInMapIcon />}
                                        className="lightbox-reset"
                                        onClick={() => resetTransform()}
                                    /> 

                                    {images.length > 1 && (
                                        <IconButton
                                            aria={isDockedRight ? "landscape" : "portrait"}
                                            toolTip={isDockedRight ? "landscape" : "portrait"}
                                            icon={isDockedRight ? <DockAtBottomIcon /> : <DockOnRightIcon />}
                                            onClick={handleIsDocked} 
                                        /> 
                                    )} 
                                    
                                    <IconButton
                                        aria="close"
                                        toolTip="close"
                                        icon={<ClearIcon />}
                                        onClick={handleClose} 
                                    /> 

                                </div>

                            </div> 
                                                            

                            <div    
                                className={`lightbox-slide
                                    ${index === currentIndex ? 'active' : ''}
                                    ${index === currentIndex - 1 
                                        || (currentIndex !== images.length - 1 && index < currentIndex)                                        
                                        || (currentIndex === images.length - 1 && index < images.length - 1 && index !== 0)
                                        || (currentIndex === 0 && index === images.length - 1 && images.length > 2) 
                                            ? 'previous'
                                            : ''
                                    }`}                                                                  
                            >  

                                <TransformComponent wrapperStyle={{ overflow: 'visible' }}> 
                                    
                                    {loadingStates[index] && (

                                        <CircularProgress />

                                    )} 

                                    <LazyLoadImage
                                        src={cloudinaryURL(image.url)} 
                                        alt={image.title || altTag}  
                                        effect="blur"
                                        placeholderSrc={cloudinaryURL(image.thumbUrl || image.url)}
                                        onLoad={() => handleImageLoad(index)}
                                    />   

                                </TransformComponent>                                    

                            </div> 

                            {playState === 'stop' && (
                                <small className={`lightbox-footer ${index === currentIndex ? 'active' : ''}`}>
                                    {image.description}
                                </small> 
                            )}                                
                            </> 
                        )} 

                    </TransformWrapper>  

                ))}  

                {images.length > 1 && (
                    <>
                    {playState !== 'stop' && (
                        <>
                        <IconButton 
                            className="lightbox-pause"
                            aria="playing"
                            icon={playState === 'playing' ? <PauseIcon /> : <PlayArrowIcon />}
                            onClick={handlePlayState}
                        />
                        <IconButton 
                            className="lightbox-stop"
                            aria="stop"
                            icon={<StopIcon style={{ color: 'red' }} />}
                            onClick={handleStop}
                        />
                        </>
                    )}  

                    <IconButton
                        className="lightbox-arrow"
                        aria="back"
                        icon={<KeyboardArrowLeftIcon />}
                        onClick={(e) => handleRotation('left')}
                    /> 

                    {playState === 'stop' && (
                        <IconButton
                            className="lightbox-play"
                            aria="play"
                            icon={<PlayArrowIcon />}
                            onClick={handlePlayState}                        
                        />
                    )}
                    
                    <IconButton
                        className="lightbox-arrow"
                        aria="next"
                        icon={<KeyboardArrowRightIcon />}
                        onClick={(e) => handleRotation('right')}
                    />   

                    {playState === 'playing' && (
                        <CircularLoader timing={timing} resetTrigger={currentIndex} />
                    )}  
                    </>
                )}

            </div> 

            {images.length > 1 && playState === 'stop' && (
                <Thumbnails
                    images={images} 
                    currentIndex={currentIndex} 
                    handleThumb={handleThumb}
                    isDockedRight={isDockedRight}
                    shouldMatchSlide={shouldMatchSlide}
                    setShouldMatchSlide={setShouldMatchSlide}
                />                 
            )}            

        </div>     
       
    )

}

export default Lightbox