import { useEffect, useState } from "react"
import StarBorderIcon from "../icons/stars/StarBorderIcon"
import StarHalfIcon from "../icons/stars/StarHalfIcon"
import StarIcon from "../icons/stars/StarIcon"

const Rating = ({ ratings }) => {

    const [ stars, setStars] = useState([])

    useEffect(() => {

        let array = []

        for (let i = 0; i < 5; i++) {     
            
            array.push(
                i + 1 <= ratings
                    ? <StarIcon key={i} />
                    : ((i + 1) - ratings) <= 0.5
                        ? <StarHalfIcon key={i} />
                        : <StarBorderIcon key={i} />
            )

        }

        setStars(array)

    }, [ratings])

    return ( <span>{stars}</span> )

}

export default Rating