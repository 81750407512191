const ProductionQuantityLimitsIcon = ({ style, aria }) => {

    return (

        <svg 
            className="icon" 
            aria-label={aria}
            viewBox="0 0 24 24" 
            style={style}
        >
            <path d="M13 10h-2V8h2v2zm0-4h-2V1h2v5zM7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2zm-8.9-5h7.45c.75 0 1.41-.41 1.75-1.03L21 4.96 19.25 4l-3.7 7H8.53L4.27 2H1v2h2l3.6 7.59-1.35 2.44C4.52 15.37 5.48 17 7 17h12v-2H7l1.1-2z" />
        </svg>

    )

}

export default ProductionQuantityLimitsIcon