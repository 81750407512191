import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { cloudinaryURL, formatPrice, scrollAnimation } from '../../utils'
import { LazyLoadImage, LazyLoadComponent  } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import Rating from '../layout/tools/Rating'
import './product.css'

const Product = ({ product, onclick }) => {

    useEffect(() => {        
        scrollAnimation()
    }) 

    return (
        
        <Link 
            to={`/product/${product.slug}`} 
            className="showroom-item fade-in"
            onClick={onclick}
        > 

            <div> 

                <figure>                    
                    <LazyLoadComponent>
                        <LazyLoadImage
                            src={product.images[0] ? cloudinaryURL(product.images[0].thumbUrl) : '/images/default-product.jpg'} 
                            alt={product.name} 
                            effect="blur"
                            placeholderSrc={product.images[0] ? cloudinaryURL(product.images[0].thumbUrl) : '/images/default-product.jpg'}
                            className="object-fit"
                            style={{ objectFit: 'scale-down', height: '100%' }} 
                        />
                    </LazyLoadComponent>
                </figure>  
                
            </div> 

            <div>    

                <h3 
                    className="text-center" 
                    style={{ margin: '10px 0 0 0', fontSize: '1rem', fontWeight: 600 }}
                >
                    {product.name}
                </h3> 

                <div className="text-center" style={{ marginBottom: 15 }}>

                    <div 
                        className="d-flex align-items-center"
                        style={{ marginBottom: 20 }}
                    >
                        <Rating ratings={product.ratings} />

                        <p style={{ marginTop: 1 }}>
                            <small>
                                ({product.numOfReviews})
                            </small> 
                        </p>
                    </div> 
                    
                    {product.stock > 0 
                        ? product.price
                            ? `${formatPrice(product.price)}`
                            : 'Unavailable'
                        : 'Out of Stock'
                    } 
                    
                </div>  

            </div>

        </Link>

    )

}

export default Product
