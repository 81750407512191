import { useEffect, useState } from 'react'
import { useDispatch, useSelector }   from 'react-redux'
import { useLocation, useNavigate }   from 'react-router-dom'
import { useMediaQuery }              from 'react-responsive'
import { useAlert }                   from 'react-alert'
import { useSpring, animated }        from 'react-spring'
import { getProducts }                from '../actions/productActions'
import { cloudinaryURL, formatPrice } from '../utils'
import Pagination                     from 'react-js-pagination'
import SlideCarousel          from './images/slideCarousel/SlideCarousel'
import Parallax               from './images/Parallax'
import Product                from './products/Product'
import Filter                 from './products/Filter'
import StructuredData         from './products/StructuredData'
import ListReviews            from './review/ListReviews'
import MetaData               from './layout/MetaData'
import RangeSlider            from './layout/forms/RangeSlider'
import RatingFilter           from './layout/tools/RatingFilter'
import Loader                 from './layout/tools/Loader'
import BuyNow                 from './layout/tools/BuyNow'
import ToolTip                from './layout/tools/ToolTip'
import RefreshIcon            from './layout/icons/play/RefreshIcon'
import KeyboardArrowLeftIcon  from './layout/icons/arrows/KeyboardArrowLeftIcon'
import KeyboardArrowRightIcon from './layout/icons/arrows/KeyboardArrowRightIcon'
import FirstPageIcon          from './layout/icons/arrows/FirstPageIcon'
import LastPageIcon           from './layout/icons/arrows/LastPageIcon'
import MenuOpenIcon           from './layout/icons/navigation/MenuOpenIcon'
import MenuIcon               from './layout/icons/navigation/MenuIcon'
import ManageSearchIcon       from './layout/icons/navigation/ManageSearchIcon'

const slidesData = [   
    {
        frontImage: "https://res.cloudinary.com/marine-graphics/image/upload/v1687157771/fishslates/slides/fish_identification_slate_kojr9d.png",
        name: 'International Shipping',
        icon: `<svg className="icon" viewBox="0 0 24 24">
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z" />
                </svg>`,
        alt: 'Fish species ID slate',
        backImage: "https://res.cloudinary.com/marine-graphics/image/upload/v1687157771/fishslates/slides/reef_id_fish_slate_vraawk.png",
    },
    {
        frontImage: "https://res.cloudinary.com/marine-graphics/image/upload/v1687157776/fishslates/slides/reef_fish_id_card_evpzfh.png",
        name: 'Buy 10 GET 50% OFF!',
        icon: `<svg className="icon" viewBox="0 0 24 24">
            <path d="M12.89 11.1c-1.78-.59-2.64-.96-2.64-1.9 0-1.02 1.11-1.39 1.81-1.39 1.31 0 1.79.99 1.9 1.34l1.58-.67c-.15-.45-.82-1.92-2.54-2.24V5h-2v1.26c-2.48.56-2.49 2.86-2.49 2.96 0 2.27 2.25 2.91 3.35 3.31 1.58.56 2.28 1.07 2.28 2.03 0 1.13-1.05 1.61-1.98 1.61-1.82 0-2.34-1.87-2.4-2.09l-1.66.67c.63 2.19 2.28 2.78 2.9 2.96V19h2v-1.24c.4-.09 2.9-.59 2.9-3.22 0-1.39-.61-2.61-3.01-3.44zM3 21H1v-6h6v2H4.52c1.61 2.41 4.36 4 7.48 4 4.97 0 9-4.03 9-9h2c0 6.08-4.92 11-11 11-3.72 0-7.01-1.85-9-4.67V21zm-2-9C1 5.92 5.92 1 12 1c3.72 0 7.01 1.85 9 4.67V3h2v6h-6V7h2.48C17.87 4.59 15.12 3 12 3c-4.97 0-9 4.03-9 9H1z" />
        </svg>`,
        alt: 'Reef species ID slate',
        backImage: "https://res.cloudinary.com/marine-graphics/image/upload/v1687157778/fishslates/slides/coral_slate_of_southeast_asia_m7hocj.png",
    },
    {
        frontImage: "https://res.cloudinary.com/marine-graphics/image/upload/v1687157771/fishslates/slides/fish_slate_boemfj.png",
        name: 'Free Shipping in Thailand',
        icon: ``,
        alt: 'Marine species ID slate',
        backImage: "https://res.cloudinary.com/marine-graphics/image/upload/v1687157771/fishslates/slides/marine_life_slate_c47xrm.png",
    }    
]

const Home = () => {   

    const alert    = useAlert()     
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' }) 
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()  

    const queryParams = new URLSearchParams(location.search)

    const [ filter,        setFilter      ] = useState('') 
    const [ currentPage,   setCurrentPage ] = useState(1) 
    const [ price,         setPrice       ] = useState([0, 1000]) 
    const [ category,      setCategory    ] = useState('') 
    const [ rating,        setRating      ] = useState(0) 
    const [ isSidebarOpen, setSideBarOpen ] = useState(false) 
    const [ reviews,       setReviews     ] = useState([]) 

    const { loading, products, resPerPage, error, filteredProductsCount } = useSelector( state => state.products )
    const { categories } = useSelector( state => state.categories )

    useEffect(() => {          

        if(error) {
            return alert.error(error)        
        }   
        dispatch(getProducts(currentPage, category, price, rating, filter)) 
    
    }, [dispatch, alert, currentPage, error, price, category, rating, filter])

    useEffect(() => {

        let array = []

        if (products) {

            for (let i = 0; i < products.length; i++) {

                if (products[i].numOfReviews > 0) {
                    
                    for (let j = 0; j < products[i].reviews.length; j++) {
                       
                        array.push(products[i].reviews[j])

                    }
                    
                }
            }

        }
        
        setReviews(array)

    }, [products])
    
    const setCurrentPageNo = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    const handlePrice = (e) => {
        setCurrentPage(1)
        let array = [...price]
        array[0] = e.target.value
        setPrice(array)
    }

    const handleCategory = (value) => {
        setCurrentPage(1)
        setCategory(value)        
    }

    const reset = () => {
        setPrice([0,1000])
        setCategory('')
        setCurrentPage(1)
        setRating(0)
    }   

    const sidebarAppear = useSpring({
        transform: isSidebarOpen ? 'translateX(0)' : 'translateX(-100%)'
    })       

    return (
        <>
        <MetaData title={'Home'} />

        {products && products[0] && (
            <StructuredData
                title={products[0].name}
                description={products[0].description}
                thumb={products[0].images[0].thumbUrl}
                image={products[0].images[0].url}
                date={products[0].datePublished}
                category={products[0].category}
                rating={products[0].ratings}
                numOfReviews={products[0].numOfReviews}
            />
        )}  
        
        <div className="container">

            <div className="wrapper" style={{ paddingBottom: 0 }}>                   

                <h1 className="text-center">Fish ID & Marine Life Slates of S.E. Asia</h1>

                <hr style={{ marginBottom: 0 }} />                               

                <SlideCarousel data={slidesData} />

                <div className="d-flex slide-buttons">   

                    <button 
                        className="button button-secondary" 
                        onClick={() => navigate('/product/deluxe-fish-id-slates')}
                    > 
                        <ManageSearchIcon style={{ color: 'white' }} aria-label="details" />   
                        &nbsp;                    
                        Details
                    </button>

                    <BuyNow 
                        slug="deluxe-fish-id-slates" 
                        quantity={1} 
                        query={queryParams.get('isCartOpen')} 
                    />               

                </div>  

            </div>  

        </div>

        <div className="container">

            <div className="wrapper" style={{ paddingTop: 0 }}>      

                <hr style={{ marginTop: 80 }} />

                <div className="contained main-content" style={{ maxWidth: '980px' }}>
                    
                    <h2>Discover the Marine Bio-Diversity: Explore Species-Rich Waters with Our PVC Plastic Slates</h2>
                    
                    <p className="contained">
                    Introducing our PVC Plastic Slates for Snorkelling and Scuba Diving: Explore the Underwater World Like Never Before!
                    </p>
                    <p>
                    Immerse yourself in the beauty of the underwater realm with our PVC Plastic Slates designed specifically for snorkelling and scuba diving enthusiasts. These durable slates feature over 300 stunning photos of marine life, captured by our expert underwater photographers over a span of five years.
                    </p>
                    
                    <h2 style={{ margin: '40px 0 20px 0' }}>Highlights:</h2>

                    <ul className="bullet-list">
                        <li style={{ lineHeight: '1.8em' }}>Durable PVC plastic construction for reliable performance underwater.</li>
                        <li style={{ lineHeight: '1.8em' }}>Offset printing ensures long-lasting quality and vibrant colors.</li>
                        <li style={{ lineHeight: '1.8em' }}>Over 300 captivating photos of corals, nudibranchs, sharks, rays, turtles, fish, urchins, seastars, and more.</li>
                        <li style={{ lineHeight: '1.8em' }}>Each slate showcases a comprehensive selection of species on two sides.</li>
                        <li style={{ lineHeight: '1.8em' }}>Educational information accompanies each photo for an enriching diving experience.</li>
                    </ul>

                    <p style={{  margin: '40px 0'}}>
                    Embark on an unforgettable underwater adventure and enhance your diving experience with our PVC Plastic Slates. Order yours today and discover the mesmerizing beauty of the ocean depths!
                    </p>

                    <hr />

                </div>

                <div className="contained">

                    <p className="text-right" style={{ marginBottom: 10 }}>
                        {filteredProductsCount ? (  
                            <>                    
                            <span style={{ color: 'var(--icon-color)' }}>
                                {filteredProductsCount} 
                            </span>
                            &nbsp;item{filteredProductsCount !== 1 && 's'}

                            {!category && rating === 0 && price[0] === 0 && (
                                <span> in&nbsp; 
                                    <span style={{ color: 'var(--icon-color)' }}>
                                        {categories && categories.length}
                                    </span> 
                                    &nbsp;
                                    <span style={{ color: 'var(--foreground)' }}>
                                    &nbsp;categories
                                    </span>
                                </span>                  
                            )}  

                            {category && (
                                <span>
                                    &nbsp;in the&nbsp; 
                                    <span style={{ color: 'var(--icon-color)' }}>
                                        {category}  
                                    </span>
                                    <span style={{ color: 'var(--foreground)' }}>
                                    &nbsp;category
                                    </span>                               
                                </span>
                            )}    

                            {rating ? (
                                <span style={{ color: 'var(--paragraph-text-color)' }}>
                                    &nbsp;with&nbsp; 
                                    <span style={{ color: 'var(--icon-color)' }}>
                                        {rating}  
                                    </span>
                                    <span style={{ color: 'var(--foreground)' }}>
                                    &nbsp;star{rating > 1 ? 's' : ''} <sup>+</sup>
                                    </span>                                  
                                </span>
                            ) : null}                      

                            {price[0] > 0 && (
                                <span  style={{ color: 'var(--paragraph-text-color)' }}>
                                    &nbsp; 
                                    from &nbsp; 
                                    <span style={{ color: 'var(--icon-color)' }}>
                                        {formatPrice(0)} baht
                                    </span>
                                    &nbsp; to &nbsp;
                                    <span style={{ color: 'var(--icon-color)' }}>
                                        {formatPrice(price[0])} baht
                                    </span>
                                </span>
                            )}
                            </>
                        ) : null}
                    </p>  

                </div>

                <div className="parent gap-4 contained">
                    
                    <aside>

                        <div className="sticky" style={{ top: 50 }}>

                            <button 
                                className="icon-button mobile-button"
                                aria-label="Side Menu"
                                onClick={() => {setSideBarOpen(!isSidebarOpen)}}
                                style={{ zIndex: 1,marginBottom: 20 }}
                            >
                                {isSidebarOpen ? <MenuOpenIcon /> : <MenuIcon />}           

                            </button>  

                            {(isSidebarOpen || !isMobile) && (

                                <animated.nav style={isMobile ? sidebarAppear : {}}> 

                                    <Filter filter={filter} setFilter={setFilter} />

                                    <div className="filters">   

                                        <div>

                                            <b style={{ display: 'block', margin: '40px 0 0 0' }}>Price range</b>

                                            <label>
                                                <RangeSlider 
                                                    min={0} 
                                                    max={1000} 
                                                    value={price[0]} 
                                                    onChange={handlePrice}
                                                />
                                            </label>

                                        </div>

                                        <div>

                                            <b style={{ display: 'block', margin: '20px 0' }}>Category</b>

                                            <ul className="bullet-list">

                                                {categories && categories.map((cat, index) => (
                                                    
                                                    <li 
                                                        key={index} 
                                                        onClick={() => handleCategory(cat.name)}
                                                        className={cat.name === category ? 'active' : ''}
                                                        style={{ display: 'list-item', fontSize: '14px' }}
                                                    >
                                                        {cat.name}
                                                    </li>

                                                ))}

                                            </ul>

                                        </div>

                                        <div>

                                            <b style={{ display: 'block', margin: '20px 0' }}>Rating</b>

                                            <RatingFilter rating={rating} setRating={setRating} />

                                        </div>  
                                        
                                    </div> 
                                    
                                    <ToolTip title="Refresh">
                                        <button 
                                            className="icon-button" 
                                            aria-label="Refresh"
                                            onClick={reset} 
                                            style={{ marginTop: 40, background: 'var(--primary-color)' }}
                                        >
                                            <RefreshIcon
                                                aria-label="Reset"                                                   
                                                style={{ color: 'white' }}                                                 
                                            />
                                        </button>
                                    </ToolTip>

                                </animated.nav>

                            )}

                        </div>

                    </aside>

                    <article>
                                                    
                        <div className="showroom">

                            {loading ? <Loader position="relative" /> : (
                                <>                        
                                {products && products.length && products.length > 0  ? (
                                    products.map(product => (
                                        <Product key={product._id} product={product} />                                    
                                    )) 
                                ) : (
                                    <p style={{ width: '100%' }}>
                                        No results ...

                                    </p>
                                )} 
                                </>                  
                            )}

                        </div>

                        <div style={{ height: 30 }}>
                            {products && products.length && resPerPage < filteredProductsCount ? (
                                <Pagination
                                    activePage={currentPage}
                                    itemsCountPerPage={resPerPage}
                                    totalItemsCount={filteredProductsCount}                        
                                    onChange={setCurrentPageNo}   
                                    nextPageText={<KeyboardArrowRightIcon />}  
                                    prevPageText={<KeyboardArrowLeftIcon />} 
                                    firstPageText={<FirstPageIcon />} 
                                    lastPageText={<LastPageIcon />}  
                                />
                            ): null}  
                        </div> 

                    </article>
                </div> 

            </div>

        </div>
       
        <Parallax url={cloudinaryURL('https://res.cloudinary.com/marine-graphics/image/upload/v1687158101/fishslates/parallax/underwater-scene-2_srbmhu.jpg')} />        

        <div className="container contained">

            <div className="wrapper">  

                <h2>See what others have said ...</h2>
                
                {reviews && reviews.length > 0 && (  
                                                
                    <ListReviews reviews={reviews} />   
                
                )}

            </div>

        </div>

        <Parallax url={cloudinaryURL('https://res.cloudinary.com/marine-graphics/image/upload/v1687158101/fishslates/parallax/underwater-scene-5_m8w6pk.jpg')} />
        </>
    )
}

export default Home