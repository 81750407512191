import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Cart from '../../modals/Cart'
import ConfirmOrder from '../../modals/ConfirmOrder'
import Details from '../../modals/Details'
import Payment from '../../modals/Payment'
import Modal from '../../modals/modal/Modal'
import Success from '../../modals/Success'
import AddToCart from './AddToCart'
import ShoppingCartIcon from '../icons/navigation/ShoppingCartIcon'
import Stripe from '../../modals/Stripe'

const BuyNow = ({ slug, quantity, disabled, query }) => {

    const navigate = useNavigate()
    const location = useLocation()

    const [ isCartOpen,    setIsCartOpen    ] = useState(false)    
    const [ isDetailsOpen, setIsDetailsOpen ] = useState(query)    
    const [ isConfirmOpen, setIsConfirmOpen ] = useState(false)    
    const [ isPaymentOpen, setIsPaymentOpen ] = useState(false)       
    const [ isSuccessOpen, setIsSuccessOpen ] = useState(false)       
    const [ isStripeOpen,  setIsStripeOpen  ] = useState(false)       

    const clearQuery = () => {
        const urlWithoutQuery = location.pathname
        if(location.search) {
            navigate(urlWithoutQuery, { replace: true })
        }
    }

    const handleCart = () => {
        setIsCartOpen(!isCartOpen)
        clearQuery()
    }

    const handleDetails = () => {
        setIsDetailsOpen(!isDetailsOpen)  
        clearQuery()
    }

    const handleConfirm = () => {
        setIsConfirmOpen(!isConfirmOpen)
        clearQuery()
    }

    const handlePayment = () => {
        setIsPaymentOpen(!isPaymentOpen)
        clearQuery()
    }

    const handleStripe = () => {
        setIsStripeOpen(!isStripeOpen)
        clearQuery()
    }

    const handleSuccess = () => {
        setIsSuccessOpen(!isSuccessOpen)
        clearQuery()
    }

    return (        
        <> 
        {slug && quantity ? (
            <AddToCart 
                slug={slug} 
                quantity={quantity} 
                label="Buy now" 
                handleCart={handleCart}
                disabled={disabled}
            />
        ) : (
            <button className="icon-button" aria-label="Cart" onClick={handleCart}>
                <ShoppingCartIcon />
            </button>
        )} 

        <Modal
            isModalVisible={isCartOpen} 
            onBackdropClick={handleCart}   
            content={ 
                <Cart  
                    setIsCartOpen={setIsCartOpen}
                    setIsDetailsOpen={setIsDetailsOpen}
                /> 
            }
        />

        <Modal
            isModalVisible={isDetailsOpen} 
            onBackdropClick={handleDetails}   
            content={ 
                <Details  
                    setIsCartOpen={setIsCartOpen}
                    setIsDetailsOpen={setIsDetailsOpen}
                    setIsConfirmOpen={setIsConfirmOpen}
                /> 
            }
        />

        <Modal
            isModalVisible={isConfirmOpen} 
            onBackdropClick={handleConfirm}   
            content={ 
                <ConfirmOrder  
                    setIsDetailsOpen={setIsDetailsOpen}
                    setIsConfirmOpen={setIsConfirmOpen}
                    setIsPaymentOpen={setIsPaymentOpen}
                /> 
            }
        />

        <Modal
            isModalVisible={isPaymentOpen} 
            onBackdropClick={handlePayment}   
            content={ 
                <Payment                      
                    setIsConfirmOpen={setIsConfirmOpen}
                    setIsPaymentOpen={setIsPaymentOpen}
                    setIsSuccessOpen={setIsSuccessOpen}
                    setIsStripeOpen={setIsStripeOpen}
                /> 
            }
        />

        <Modal
            isModalVisible={isStripeOpen} 
            onBackdropClick={handleStripe}   
            content={ 
                <Stripe                      
                    setIsStripeOpen={setIsStripeOpen}
                    setIsPaymentOpen={setIsPaymentOpen}
                    setIsSuccessOpen={setIsSuccessOpen}
                /> 
            }
        />

        <Modal
            isModalVisible={isSuccessOpen} 
            onBackdropClick={handleSuccess}   
            content={ 
                <Success setIsSuccessOpen={setIsSuccessOpen} /> 
            }
        />
        </>

    )

}

export default BuyNow