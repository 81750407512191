import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import store from './store'
import App from './App'
import './index.css'
import './app.css'
import { HelmetProvider } from 'react-helmet-async'

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  transition: transitions.SCALE
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <Provider store={store}>
      <AlertProvider template={AlertTemplate} {...options}>
        <HelmetProvider>
          <Router>
            <App />
          </Router>
        </HelmetProvider>
      </AlertProvider>
    </Provider>
)
