import { formatPrice } from '../../../utils'

const RangeSlider = ({ min, max, value, onChange }) => {

    const handleSliderChange = (e) => {
      onChange(e)
    }

    return (
      <div style={{ margin: '40px 0' }}>
          <input
            type="range"
            min={min}
            max={max}
            value={value}
            onChange={handleSliderChange}
            style={{ backgroundSize: `${value / 10}% 100%` }}
          />
          <p>
          <small>{formatPrice(value)}</small>
          </p>
      </div>
    )
}

export default RangeSlider
