import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { formatPrice } from '../../utils'
import CheckIcon from '../layout/icons/actions/CheckIcon'
import ArrowBackIcon from '../layout/icons/arrows/ArrowBackIcon'
import QuestionMarkIcon from '../layout/icons/navigation/QuestionMarkIcon'
import CheckoutSteps from '../layout/tools/CheckoutSteps'
import ToolTip from '../layout/tools/ToolTip'

const ConfirmOrder = ({ setIsDetailsOpen, setIsConfirmOpen, setIsPaymentOpen }) => {

    const { cartItems, shippingInfo } = useSelector( state => state.cart )

    const proceedToPayment = () => {  
          
        const data = {
            itemsPrice: itemsPrice,
            shippingPrice,
            discount,
            taxPrice,
            totalPrice
        }
        sessionStorage.setItem('orderInfo', JSON.stringify(data))
        setIsConfirmOpen(false)  
        setIsPaymentOpen(true)
    }

    const handleBack = () => {        
        setIsConfirmOpen(false)
        setIsDetailsOpen(true)  
    }

    const calculateTotalShipping = () => {

        let shippingCost = 0

        if (shippingInfo.country === 'Thailand' )return shippingCost
      
        cartItems.forEach((item) => {
          
            shippingCost += item.shipping + (item.shippingTwo * (item.quantity - 1))
          
        })      
        return shippingCost
    }

    const calcSubTotal = () => {

        let subTotal = 0       

        cartItems.forEach((item) => {
          
            subTotal += item.quantity > 9 
                ? item.price * 2 * item.quantity 
                : item.price * item.quantity 
          
        })  
        return subTotal

    }

    const calcTotal = () => {

        let total = 0       

        cartItems.forEach((item) => {
          
            total += item.price * item.quantity 
          
        })  
        return total + shippingPrice

    }
    // Calculate Order Prices
    const itemsPrice    = calcSubTotal()    
    const shippingPrice = calculateTotalShipping()
    const totalPrice    = calcTotal()
    const discount      = cartItems.reduce((acc, item) => acc + item.discount, 0)
    const taxPrice      = Number((0.05 * itemsPrice).toFixed(2))    
   
    return (   
        <>
        <button 
            className="icon-button" 
            aria-label="back"
            onClick={handleBack}
            style={{ margin: '5px 0 0 5px' }}
        >

            <ArrowBackIcon />

        </button>   

        <CheckoutSteps confirm />

        <div className="wrapper">  

            <table className="cart-table" style={{ marginBottom: 40 }}>
                <tbody>
                    <tr>
                        <th style={{ background: 'none' }}></th>
                        <th className="text-center" style={{ background: 'none' }}>Qty</th>
                        <th className="text-right" style={{ background: 'none' }}>Price</th>
                        <th className="text-right" style={{ background: 'none' }}>Discount</th>
                        <th className="text-right" style={{ background: 'none' }}>Ship</th>                        
                        <th className="text-right" style={{ background: 'none' }}>Sub</th>
                    </tr>
                    {cartItems && cartItems.map((item, index) => (
                        <tr key={index}>
                            <th className="text-center">
                                <Link to={`/product/${item.slug}`}>
                                    <img
                                        src={item.image} 
                                        alt={item.name} 
                                        style={{ width: 50 }}
                                    />
                                    <br />   
                                    <b><small>{item.name}</small></b>
                                </Link>
                            </th>
                            <td className="text-center">{item.quantity}</td>

                            <td className="whitespace-nowrap text-right">{formatPrice(item.quantity > 9 ? item.price * 2 : item.price, '฿')}</td>
                            
                            <td className={`whitespace-nowrap text-right relative ${item.discount > 0 ? 'discount-two' : null}`}>

                                {formatPrice(item.discount / item.quantity, '฿')}

                                <small style={{ color: '#999', fontSize: '10px', position: 'absolute', top: '20px', right: 0 }}>

                                    <ToolTip 
                                        title={`Buy 10 or more units and instantly receive a 50% discount!`}
                                        style={{ width: 150 }}
                                    >
                                        <div 
                                            className="d-flex justify-content-center align-items-center"
                                            style={{ height: 16, width: 16, background: 'white', borderRadius: '50%', position: 'absolute', top: '-30px', right: 0 }}
                                        >
                                            &nbsp;
                                            <QuestionMarkIcon style={{ fontSize: '0.75rem' }} />
                                        </div>
                                    </ToolTip>

                                </small>
                                
                            </td>

                            <td className={`whitespace-nowrap text-right relative ${shippingPrice === 0 ? 'discount-two' : null}`}>
                                
                                {shippingPrice === 0
                                    ? '0 ฿'
                                    : formatPrice(item.shipping + ((item.quantity - 1) * item.shippingTwo), '฿')} 
                                                                 
                                <small style={{ color: '#999', fontSize: '10px', position: 'absolute', top: '20px', right: 0 }}>
                                    <ToolTip 
                                        title={`First item costs ${formatPrice(item.shipping, '฿')}, 2 or more items incur a fee of ${formatPrice(item.shippingTwo, '฿')} per unit. Postage within Thailand is FOC`}
                                        style={{ width: 150 }}
                                    >
                                        <div 
                                            className="d-flex justify-content-center align-items-center"
                                            style={{ height: 16, width: 16, background: 'white', borderRadius: '50%', position: 'absolute', top: '-30px', right: 0 }}
                                        >
                                            &nbsp;
                                            <QuestionMarkIcon style={{ fontSize: '0.75rem' }} />
                                        </div>
                                    </ToolTip>
                                </small>
                                                        
                            </td>
                           
                            <td className="text-right whitespace-nowrap">
                                {shippingPrice === 0
                                    ? formatPrice( item.quantity * item.price, '฿' )
                                    : formatPrice( item.quantity * item.price + item.shipping + (item.quantity -1) * item.shippingTwo, '฿')}
                            </td>
                        </tr>
                    ))}
                </tbody>

            </table> 

            <table className="cart-table" style={{ marginBottom: 40 }}>
                <tbody>                                                   
                    <tr>
                        <th className="text-right" style={{ width: 120 }}>Name</th>
                        <td className="text-right">{shippingInfo.name}</td>
                    </tr>
                    
                    <tr>
                        <th className="text-right" style={{ verticalAlign: 'top' }}>Address</th>
                        <td className="text-right">
                            {shippingInfo.address}
                            <br />
                            {shippingInfo.city}
                            <br />
                            {shippingInfo.subDistrict}
                            <br />
                            {shippingInfo.district}
                            <br />
                            {shippingInfo.postalCode.toUpperCase()}
                            <br />                            
                            {shippingInfo.country}
                        </td>
                    </tr>  

                    <tr>
                        <th className="text-right">Phone</th>
                        <td className="text-right">
                            {shippingInfo.phoneNo}
                            <img
                                alt={shippingInfo.country} 
                                src={`https://flagcdn.com/${shippingInfo.countryCode}.svg`}
                                style={{ width: '20px', marginLeft: '10px' }}
                            />
                            
                        </td>
                    </tr> 
                </tbody>
            </table>           

            <table className="cart-table">
                <tbody>
                    <tr>
                        <th 
                            className="text-right" 
                            style={{ width: 120 }}
                        >
                            Subtotal
                        </th>
                        <td className="text-right">
                            {formatPrice(itemsPrice)}
                        </td>    
                    </tr>
                    <tr>
                        <th className={`text-right relative ${shippingPrice === 0 ? 'discount' : null}`}>
                            Shipping
                        </th>   
                        <td className={`text-right relative ${shippingPrice === 0 ? 'discount-two' : null}`}>
                            {formatPrice(shippingPrice)}
                        </td>    
                    </tr>
                    <tr> 
                    <th className={`text-right relative ${discount > 0 ? 'discount' : null}`}>
                            Discount                           
                        </th>
                        <td className={`text-right relative ${discount > 0 ? 'discount-two' : null}`}>
                            {discount > 0 ? '-' : ''} {formatPrice(discount)}
                        </td>  
                    </tr>
                    <tr>
                        <th className="text-right">                           
                            Total
                        </th>                           
                        <td className="text-right">
                            {formatPrice(totalPrice)}
                        </td>
                    </tr>
                </tbody>

            </table>   

            {shippingPrice === 0 && (
                <p style={{ color: 'red' }} className="text-right">
                    <small>* Free shipping in Thailand</small>
                </p>
            )}

            <button
                className="button"
                onClick={proceedToPayment}
                style={{ marginTop: 40 }}
            >
                <CheckIcon style={{ color: 'white' }} /> 
                &nbsp;
                Confirm
            </button>
            
        </div>
        </>  
    )

}

export default ConfirmOrder