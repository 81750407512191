import CheckIcon from '../layout/icons/actions/CheckIcon'

const Confirm = (props) => {
    return (
        <div className="wrapper">

            <h3>{props.message}?</h3> 

            <button
                className="button"
                onClick={() => {
                    props.onConfirm()
                    props.onBackdropClick()
                }}
            >
                <CheckIcon style={{ color: 'white' }} /> 
                &nbsp;
                Confirm
            </button>
                        
        </div>
    )
}

export default Confirm
