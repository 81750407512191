import { useState } from "react"
import StarBorderIcon from "../icons/stars/StarBorderIcon"
import StarIcon from "../icons/stars/StarIcon"

const RatingFilter = ({ rating, setRating, style }) => {

    const [filled, setFilled] = useState(rating || 0)

    const handleMouseEnter = (index) => {
        setFilled(index)
    }

    const handleMouseLeave = () => {
        setFilled(rating)
    }

    const handleStarClick = (index) => {
      setRating(index)      
    }

    const renderStars = () => {
        const stars = []
        const maxRating = 5
    
        for (let i = 1; i <= maxRating; i++) {
          const isFilled = i <= filled
    
          stars.push(
            <span
              key={i}
              onMouseEnter={() => handleMouseEnter(i)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleStarClick(i)}
            >
              {isFilled ? <StarIcon /> : <StarBorderIcon />}
            </span>
          )
        }
    
        return stars
      }

      return (

        <div className="rating-filter" style={style}>
          {renderStars()}
        </div>

      )

}

export default RatingFilter