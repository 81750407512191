import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

import { searchReducer, productsReducer, navProductsReducer, productDetailsReducer, adminProductDetailsReducer, newProductReducer, productReducer, newReviewReducer, productReviewsReducer, reviewReducer } from './reducers/productReducers'
import { categoriesReducer, adminCategoriesReducer, newCategoryReducer, categoryReducer, categoryDetailsReducer } from './reducers/categoryReducers'
import { authReducer, userReducer, forgotPasswordReducer, allUsersdReducer, userDetailsReducer } from './reducers/userReducers'
import { newOrderReducer, myOrdersReducer, orderDetailsReducer, allOrdersReducer, orderReducer } from './reducers/orderReducers'
import { cartReducer } from './reducers/cartReducers'

const reducer = combineReducers({    

    auth: authReducer,
    user: userReducer,
    allUsers: allUsersdReducer,
    userDetails: userDetailsReducer,
    forgotPassword: forgotPasswordReducer,

    categories: categoriesReducer,
    adminCategories: adminCategoriesReducer,
    newCategory: newCategoryReducer,
    category: categoryReducer,
    categoryDetails: categoryDetailsReducer,

    products: productsReducer,    
    navProducts: navProductsReducer,    
    searchResults: searchReducer,    
    productDetails: productDetailsReducer,
    adminProductDetails: adminProductDetailsReducer,    
    newProduct: newProductReducer,
    product: productReducer,

    productReviews: productReviewsReducer,
    review: reviewReducer,
    newReview: newReviewReducer,

    cart: cartReducer,
    newOrder: newOrderReducer,
    myOrders: myOrdersReducer,
    allOrders: allOrdersReducer,
    orderDetails: orderDetailsReducer,
    order: orderReducer,
    
})

let initialState = {
    cart: {
        cartItems: localStorage.getItem('cartItems') 
            ? JSON.parse(localStorage.getItem('cartItems'))
            : [],
        shippingInfo: localStorage.getItem('shippingInfo') 
            ? JSON.parse(localStorage.getItem('shippingInfo'))
            : {}
    }
}

const middleware = [thunk]
const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store