const ArrowLeftIcon = ({ style, aria }) => {

    return (

        <svg style={style} aria-label={aria} className="icon" viewBox="0 0 24 24">
            <path d="m14 7-5 5 5 5V7z" />
        </svg>

    )

}

export default ArrowLeftIcon