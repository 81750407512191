import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { addItemToCart, removeItemFromCart, emptyCart } from '../../actions/cartActions'
import { formatPrice } from '../../utils'
import AddIcon from '../layout/icons/actions/AddIcon'
import DeleteOutlineIcon from '../layout/icons/actions/DeleteOutlineIcon'
import RemoveIcon from '../layout/icons/actions/RemoveIcon'
import DeleteForeverIcon from '../layout/icons/actions/DeleteForeverIcon'
import ToolTip from '../layout/tools/ToolTip'
import ProductionQuantityLimitsIcon from '../layout/icons/navigation/ProductionQuantityLimitsIcon'
import CurrencyExchangeIcon from '../layout/icons/navigation/CurrencyExchangeIcon'
import GlobeIcon from '../layout/icons/navigation/GlobeIcon'

const Cart = ({ setIsCartOpen, setIsDetailsOpen }) => {

    const dispatch = useDispatch()
    const location = useLocation()

    const [ country, setCountry ] = useState()

    const { cartItems } = useSelector( state => state.cart )
    const { user      } = useSelector( state => state.auth )  

    let totalPrice = cartItems.reduce((acc, item) => acc + item.quantity * item.price, 0)
    let discount   = cartItems.reduce((acc, item) => acc + item.discount, 0)

    const calcSubTotal = () => {

        let subTotal = 0       

        cartItems.forEach((item) => {
          
            subTotal += item.quantity > 9 
                ? item.price * 2 * item.quantity 
                : item.price * item.quantity 
          
        })  
        return subTotal

    }

    const getUserLocation = () => {

        const success = (position) => {

            const latitude = position.coords.latitude
            const longitude = position.coords.longitude

            const geoApiUrl = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`

            fetch(geoApiUrl)
                .then( res => res.json() )
                .then( data => setCountry(data.countryName) ) 
        }

        const error = () => {
            return 
        }

        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(success, error)
        }
       
    }    

    const calculateTotalShipping = (cartItems) => {

        let shippingCost = 0
        getUserLocation()

        if (country && country === 'Thailand') return shippingCost
      
        cartItems.forEach((item) => {
            shippingCost += item.quantity === 1 
                ? item.shipping 
                : item.shipping + (item.shippingTwo * (item.quantity - 1))       
        })      
        return shippingCost
    }

    const shipping = calculateTotalShipping(cartItems)

    const subTotal = calcSubTotal()
      
    const removeCartItemHandler = (id) => {
        dispatch(removeItemFromCart(id))
    }
    const emptyCartHandler = () => {   
        dispatch(emptyCart())
    }
    const increaseQty = (id, quantity, stock) => {
        const newQty = quantity + 1
        if(newQty > stock) { return }
        dispatch(addItemToCart(id, newQty))
    }
    const decreaseQty = (id, quantity) => {
        const newQty = quantity - 1
        if(newQty <= 0) { return }
        dispatch(addItemToCart(id, newQty))
    }    
    const checkoutHandler = () => {    
        setIsCartOpen(false)  
        setIsDetailsOpen(true)         
    }

    return (        
        <>
        <div className="wrapper relative">
            {cartItems.length === 0 
                ? ( <>
                    <h2>Shopping Cart</h2> 
                    <div 
                        className="d-flex align-items-center justify-content-center direction-column"
                        style={{ height: 'calc(100vh - 160px)' }}
                    >
                        
                        <ProductionQuantityLimitsIcon style={{fontSize: '5rem'}} />     

                        <p style={{ marginTop: 40 }}>Shopping Cart is empty</p>  

                    </div>                     
                    </>
                ) : (
                    <>                        
                    <h2>Shopping Cart</h2> 

                    <p className="cart-banner">
                        <CurrencyExchangeIcon style={{ color: 'white' }} />
                        Buy 10 GET 50% OFF!
                    </p>

                    <table className="cart-table">
                        <tbody>
                            <tr>  
                                <th 
                                    colSpan="3" 
                                    style={{ background: 'none' }}
                                >
                                    <ToolTip title="Empty cart">
                                        <button 
                                            className="icon-button" 
                                            onClick={emptyCartHandler}  
                                        >
                                            <DeleteForeverIcon />
                                        </button>
                                    </ToolTip>
                                </th>                                
                            </tr>
                            {cartItems && cartItems.map((item, index) => (
                                <tr key={index}>
                                    <th style={{ width: 150 }}  className="text-center">
                                        <Link to={`/product/${item.slug}`}>
                                            <img
                                                src={item.image} 
                                                alt={item.name} 
                                                style={{ width: 50 }}
                                            />
                                            <br />   
                                            <b><small>{item.name}</small></b>
                                        </Link>

                                        <br />

                                        {formatPrice(item.price)}
                                    </th>
                                    <td>
                                        <div className="d-flex justify-content-end" style={{ gap:5 }}>                                 

                                            <ToolTip title="Decrease">
                                                <button 
                                                    className={item.quantity <= 1 
                                                        ? 'icon-button pointer-events-none' 
                                                        : 'icon-button'
                                                    } 
                                                    onClick={() => decreaseQty(item.slug, item.quantity)}
                                                >
                                                    <RemoveIcon className={item.quantity <= 1 ? 'danger ' : ''} />
                                                </button>
                                            </ToolTip>

                                            <input 
                                                className="text-center"
                                                value={item.quantity} 
                                                style={{ color: 'var(--icon-color)', width: '40px' }}
                                                readOnly 
                                            /> 
                                            
                                            <ToolTip title="Increase">
                                                <button 
                                                    className={item.quantity === item.stock 
                                                        ? 'icon-button pointer-events-none' 
                                                        : 'icon-button'
                                                    } 
                                                    onClick={() => increaseQty(item.slug, item.quantity, item.stock)}
                                                >
                                                    <AddIcon />
                                                </button>       
                                            </ToolTip>                                           

                                        </div>
                                    </td>

                                    <td>
                                        <ToolTip title="Remove">
                                            <button 
                                                className="icon-button" 
                                                onClick={() => removeCartItemHandler(item.product)}
                                            >
                                                <DeleteOutlineIcon />
                                            </button>  
                                        </ToolTip>  
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>                              

                    <h4
                        className="text-right"
                        style={{ margin: '40px 0 20px 0', color: '#999' }}
                    >
                        Summary
                    </h4>
                    
                    <table className="cart-table">
                    <tbody>
                        <tr>
                            <th className="text-right" style={{ width: '150px' }}>
                                Units
                            </th>                            
                            <td className="text-right">
                                {cartItems.reduce((acc, item) => (acc + Number(item.quantity)), 0)} 
                            </td>
                        </tr>

                        <tr>
                            <th className="text-right">Sub total</th>
                            <td className="text-right">
                                {formatPrice(subTotal)} 
                            </td>
                        </tr>

                        <tr>
                            <th className={`text-right ${shipping === 0 ? 'discount' : null}`}>
                                Shipping
                            </th>
                            <td className={`text-right ${shipping === 0 ? 'discount-two' : null}`}>
                                {formatPrice(shipping)} 
                            </td>
                        </tr>

                        <tr>
                            <th className={`text-right ${discount > 0 ? 'discount' : null}`}>
                                Discount
                            </th>
                            <td className={`text-right ${discount > 0 ? 'discount-two' : null}`}>
                                {discount > 0 ? '-' : ''} {formatPrice(discount)} 
                            </td>
                        </tr>

                        <tr>
                            <th className="text-right">Total</th>
                            <td className="text-right">{formatPrice(totalPrice + shipping)}</td>
                        </tr>
                    </tbody>
                    </table>  

                    {shipping === 0 && (
                        <p style={{ color: 'red' }} className="text-right">
                            <small>* Free shipping in Thailand</small>
                        </p>
                    )}

                    {user ? (
                        <button
                            className="button"
                            onClick={checkoutHandler}
                            style={{ marginTop: 40 }}
                        >
                            <GlobeIcon style={{ color: 'white' }} />
                            &nbsp;
                            Shipping
                        </button>
                    ) : (
                        <div className="parent" style={{ marginTop: 40 }}>
                            <Link 
                                style={{ flex: 1, width: '100%' }} 
                                to={`/login?redirect=${location.pathname}&isCartOpen=true`}
                            >
                                <button
                                    className="button button-secondary" 
                                    onClick={() => setIsCartOpen(false)}                                    
                                >
                                    Login to continue
                                </button>
                            </Link>
                            <Link 
                                style={{ flex: 1, width: '100%' }} 
                                to={`/register?redirect=${location.pathname}&isCartOpen=true`}
                            >
                                <button
                                    className="button"
                                    onClick={() => setIsCartOpen(false)}                                    
                                >
                                    Register an account
                                </button>
                            </Link>
                        </div>
                    )}
                    </>
                )}             
        </div>
        </>
    )

}

export default Cart