import CurrencyConverterForm from '../layout/forms/CurrencyConverterForm'

const CurrencyConverter = ({ value }) => {

    return (

        <div className="wrapper">
        
            <CurrencyConverterForm value={value} />

        </div>
        
    )
}

export default CurrencyConverter