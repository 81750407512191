import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createOrder, clearErrors } from '../../actions/orderActions'
import { emptyCart } from '../../actions/cartActions'
import { useAlert } from 'react-alert'
import { CREATE_ORDER_RESET } from '../../constants/orderConstants'
import ArrowBackIcon from '../layout/icons/arrows/ArrowBackIcon'
import CheckoutSteps from '../layout/tools/CheckoutSteps'
import axios from 'axios'
import PaypalButton from '../layout/tools/PaypalButton'
import LockIcon from '../layout/icons/login/LockIcon'

const Payment = ({ setIsConfirmOpen, setIsPaymentOpen, setIsSuccessOpen, setIsStripeOpen }) => {

    const alert    = useAlert()    
    const dispatch = useDispatch()

    const [ isReady, setIsReady ] = useState(false)
   
    const { error, order: newOrder  } = useSelector( state => state.newOrder )
    const { cartItems, shippingInfo } = useSelector( state => state.cart )

    useEffect(() => {
        if(error) {
            alert.error(error)
            dispatch(clearErrors())
        }
    }, [dispatch, alert, error])
       

    const handleBack = () => {        
        setIsConfirmOpen(true)
        setIsPaymentOpen(false)  
    }

    const handleStripe = () => {
        setIsStripeOpen(true)
        setIsPaymentOpen(false)  
    }

    const order = {
        orderItems: cartItems,
        shippingInfo
    }

    const orderInfo = JSON.parse(sessionStorage.getItem('orderInfo'))

    if(orderInfo) {        
        order.itemsPrice    = orderInfo.itemsPrice
        order.shippingPrice = shippingInfo.country === 'Thailand' ? 0 : orderInfo.shippingPrice
        order.taxPrice      = orderInfo.taxPrice
        order.totalPrice    = orderInfo.totalPrice
        order.discount      = orderInfo.discount
    }

    const orderHandler = (id, type) => {  
        order.paymentInfo = {
            id: id,
            type: type,
            status: 'COMPLETED'
        }  
        dispatch(createOrder(order))   
        setIsReady(true)     
    }  

    useEffect(() => {
        const sendEmail = () => {             
            
            const formData = new FormData()
            formData.set('id',             newOrder.order._id)   
            formData.set('address',        newOrder.order.shippingInfo.address) 
            formData.set('city',           newOrder.order.shippingInfo.city) 
            formData.set('subDistrict',    newOrder.order.shippingInfo.subDistrict) 
            formData.set('district',       newOrder.order.shippingInfo.district) 
            formData.set('postalCode',     newOrder.order.shippingInfo.postalCode) 
            formData.set('country',        newOrder.order.shippingInfo.country) 
            formData.set('countryCode',    newOrder.order.shippingInfo.countryCode) 
            formData.set('email',          newOrder.order.shippingInfo.email) 
            formData.set('name',           newOrder.order.shippingInfo.name) 
            formData.set('phone',          newOrder.order.shippingInfo.phoneNo)      
            formData.set('itemsPrice',     newOrder.order.itemsPrice) 
            formData.set('shippingPrice',  newOrder.order.shippingPrice) 
            formData.set('totalPrice',     newOrder.order.totalPrice) 
            formData.set('discount',       newOrder.order.discount) 
            formData.set('createdAt',      newOrder.order.createdAt) 
            formData.set('orderStatus',    newOrder.order.orderStatus)                
            formData.set('paymentId',      newOrder.order.paymentInfo.id)                
            formData.set('paymentType',    newOrder.order.paymentInfo.type)                
            formData.set('paymentStatus',  newOrder.order.paymentInfo.status)                
            newOrder.order.orderItems.forEach(item => {
                formData.append('items', JSON.stringify(item))
            })      
    
            axios.post( '/api/v1/booking', formData )
                .then(res => {  
                    dispatch(emptyCart())
                    setIsPaymentOpen(false)  
                    setIsSuccessOpen(true)   
                    setIsReady(false)  
                }).catch((res) => {  
                    console.log(res)
                })   
        }
        if (isReady && newOrder) {
            sendEmail()
            dispatch({ type: CREATE_ORDER_RESET })
            setIsReady(false)  
        }
    }, [dispatch, setIsPaymentOpen, setIsSuccessOpen, newOrder, isReady])      
  
    return (  
        <>
        <button 
            className="icon-button" 
            aria-label="back"
            onClick={handleBack}
            style={{ margin: '5px 0 0 5px' }}
        >

            <ArrowBackIcon />

        </button>   

        <CheckoutSteps payment />

        <div className="wrapper">  

        <h2>Please choose a payment option</h2>

            <PaypalButton 
                cartItems={cartItems} 
                orderHandler={orderHandler}
                country={shippingInfo.country}
            />

            <button 
                className="button" 
                onClick={handleStripe}
                style={{ textTransform: 'lowercase', borderRadius: 5, background: '#6772e5', fontWeight: 'bold', fontSize: '22px', padding: '12px', marginTop: 40 }}
            >
                stripe
            </button>

            <p 
                className="text-center" 
                style={{ 
                    marginBottom: 80, 
                    color: 'black', 
                    fontSize: '16px', 
                    letterSpacing: 'initial', 
                    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif'  
                }}
            >
                Pay securely with a credit card
            </p>

            <img 
                src="/images/credit-cards.jpg" 
                alt="We accept all major credit cards" 
                style={{ width: '50%', margin: 'auto', display: 'block' }}
            />

            <div className="text-center" style={{ marginTop: 80 }}>
                <LockIcon style={{ fontSize: '8rem', opacity: '0.1' }} />
            </div>
                        
        </div>
        </>          
    )

}

export default Payment