const AddIcon = ({ style, aria }) => {

    return (

        <svg style={style} aria-label={aria} className="icon"  viewBox="0 0 24 24" >
            <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
        </svg>

    )

}

export default AddIcon