import { useSpring, animated } from '@react-spring/web'
import ReactDOM                from 'react-dom'
import './lightbox-modal.css'

 const LightboxModal = ({ isModalVisible, content }) => {

  const animation = useSpring({           
    opacity:   isModalVisible ? 1 : 0,
    transform: isModalVisible ? 'translateX(0)' : 'translateX(100%)'        
  })    

  if (!isModalVisible) return null

  return ReactDOM.createPortal( 
    
    <animated.div className="lightbox-modal" style={animation}>  

      {content}        
      
    </animated.div>,
    
    document.getElementById('lightbox-modal')    
  )
}

export default LightboxModal
