import HomeIcon from './layout/icons/navigation/HomeIcon'
import { Link } from 'react-router-dom'

const Error404 = () => {

    return (

        <div className="contained">

            <div className="wrapper">

                <h1>Woops! 404</h1>

                <h2>Page not found!</h2>

                <Link to="/">

                    <button className="button">

                        <HomeIcon style={{ color: 'white' }} /> &nbsp; Home

                    </button>

                </Link>

            </div>
            
        </div>
        
    )

}

export default Error404