import { useState } from 'react'
import { useSelector } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'
import Input from '../layout/forms/Input'
import Loader from '../layout/tools/Loader'
import axios from 'axios'
import SendIcon from '../layout/icons/navigation/SendIcon'

function Contact() {

    const { user } = useSelector( state => state.auth )  

    const [ name,        setName        ] = useState((user && user.name)  || '')
    const [ email,       setEmail       ] = useState((user && user.email) || '')
    const [ message,     setMessage     ] = useState('')
    const [ captcha,     setCaptcha     ] = useState(false)
    const [ success,     setSuccess     ] = useState(false)   
    const [ thinking,    setThinking    ] = useState(false)   
    const [ image ] = useState(user 
        ? user.avatar 
            ? user.avatar.url 
            : 'https://res.cloudinary.com/marine-graphics/image/upload/v1687028506/fishslates/logo/default-avatar_iuxxjq.jpg'
        : 'https://res.cloudinary.com/marine-graphics/image/upload/v1687028506/fishslates/logo/default-avatar_iuxxjq.jpg'
    )   

    const submitHandler = (e) => {
        e.preventDefault()         
        setCaptcha(true)  
    }

    const onChange = (value) => {      
        
        const formData = new FormData()
        formData.set('name', name)
        formData.set('email', email)
        formData.set('message', message)   
        formData.set('image', image)
        formData.set('key', value)      
        
        setThinking(true)
        
        axios.post( '/api/v1/contact', formData )
            .then(res => {
                setSuccess(true)
                setCaptcha(false)
                setThinking(false)
            }).catch((res) => {
                setCaptcha(false)
                setThinking(false)
                console.log(res)
            })                        
    }
    
    return (  
        <>
        {!captcha ? (

            !success ? (
                <>  
                <form onSubmit={submitHandler} className="wrapper">   

                    <h2>Contact Us</h2>   

                    <div className="mobile-parent" style={{ gap: 2, marginBottom: 40 }}>     

                        <Input
                            type="text"
                            placeholder="Name"
                            value={name} 
                            onChange={(e) => setName(e.target.value)} 
                            filled={name ? true : false}
                            required
                        /> 

                        <Input
                            type="text"
                            placeholder="Email"
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            filled={email ? true : false}
                            required
                        />

                    </div>                     
                   
                    <Input 
                        type="textarea"
                        placeholder="Message"
                        value={message} 
                        onChange={(e) => setMessage(e.target.value)}                         
                        rows="10"
                        filled={message ? true : false}
                        required
                    />

                    <button 
                        type="submit"                             
                        className="button"
                        disabled={ !name || !email || !message ? true : false }
                        style={{ marginTop: 40 }}
                    >
                        <SendIcon style={{ color: 'white' }} />
                        &nbsp;
                        Send Email
                    </button>
                        
                </form>  
                </>
            ) : (
                <div className="wrapper">
                    <h2>Mail sent!</h2>

                    <div className="text-center" style={{ marginBottom: 80 }}>

                        <SendIcon style={{ fontSize: '5rem', color: 'var(--cta-green)' }} />

                    </div>                        

                    <p>
                        Thank you for your email. We will get back to you soon. 
                        <br />
                        <br />
                        Check your 'junk' mail if you don't hear from us. 
                        <br />
                        <br />
                        You may now close this tab
                    </p>
                </div>
            )  
        ) : (
            <>
            {thinking && (                
                <Loader position="absolute" />
            )}
            <div className="wrapper">
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={onChange}
                />
            </div>             
            </>
        )}
        </>  
    )

}

export default Contact
