const ExpandLessIcon = ({ style, aria }) => {

    return (

        <svg className="icon" viewBox="0 0 24 24" style={style} aria-label={aria}>
            <path d="m12 8-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
        </svg>

    )

}

export default ExpandLessIcon