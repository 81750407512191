import { PayPalButtons } from '@paypal/react-paypal-js'
import { useAlert } from 'react-alert'

const PaypalButton = ({ cartItems, orderHandler, country }) => {   

    const alert = useAlert()   
    const items = []

    cartItems.forEach(function(item) {        
        const newItem = {
            id: item.product,
            quantity: item.quantity
        }
        items.push(newItem)
    })
    
    return (
      <>
      <PayPalButtons 

        style= {{
            layout: 'horizontal',
            color:  'blue', // silver
            shape:  'rect',  // pill              
            label:  'paypal', 
            height: 55,
            tagline: true 
        }}           

        createOrder = {() => {                
            return fetch(`/api/v1/payment/process`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ country: country, items: items })                    
            })  
            .then(res => {                  
                return res.ok 
                  ? res.json() 
                  : res.json().then(json => Promise.reject(json))
            })
            .then(({ id }) => {
                return id
            })
            .catch(e => {
                console.error(e.error)
                alert.error('Error creating order: ' + e.message)
            })          
        }}           

        onApprove = {async (data, actions) => {
            try {
              const details = await actions.order.capture()
              if (details.status === 'COMPLETED') {
                orderHandler(data.orderID, data.paymentSource)
                alert.success('Transaction complete')
              } else {
                throw new Error('Payment not completed')
              }
            } catch (err) {
              console.error('Error capturing order:', err)
              alert.error('Error capturing order: ' + err.message)
            }
        }}

        onCancel = {() => {
          alert.error('Transaction cancelled')
        }}

        onError = {(err) => {
          console.error('Paypal error', err)
          alert.error('Paypal error: '+ err)
        }}
          
      />
      </>
    )

}

export default PaypalButton