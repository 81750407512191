import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { newReview } from '../../actions/productActions'
import RichtextEditor from '../layout/richtext/RichtextEditor'
import Loader from '../layout/tools/Loader'
import RatingFilter from '../layout/tools/RatingFilter'
import axios from 'axios'

function Review(props) {
    
    const dispatch = useDispatch()

    const { loading } = useSelector( state => state.newReview ) 
    const { product } = useSelector( state => state.productDetails )
    const { user    } = useSelector( state => state.auth )
    
    const [ rating,  setRating  ] = useState( props.rating )
    const [ comment, setComment ] = useState( props.comment )  
    const [ image ] = useState(user 
        ? user.avatar 
            ? user.avatar.url 
            : 'https://res.cloudinary.com/marine-graphics/image/upload/v1687028506/fishslates/logo/default-avatar_iuxxjq.jpg'
        : 'https://res.cloudinary.com/marine-graphics/image/upload/v1687028506/fishslates/logo/default-avatar_iuxxjq.jpg'
    )      
    
    const sendEmail = () => {  
            
        const formData = new FormData()
        formData.set('name', user.name)
        formData.set('email', user.email)
        formData.set('image', image)
        formData.set('rating', rating)
        formData.set('comment', comment)
        formData.set('productId', product._id)
        
        axios.post( '/api/v1/reviewEmail', formData )           
    }

    const reviewHandler = (e)  => {
        e.preventDefault()
        const formData = new FormData()
        formData.set('rating', rating)
        formData.set('comment', comment)
        formData.set('productId', product._id)
        formData.set('visible', false)
        dispatch(newReview(formData))  
        sendEmail()  
    }
    
    return (  
        <>        
        {loading ? <Loader /> : (

            <form onSubmit={reviewHandler} className="wrapper">

                <h2>Post Review</h2>    

                <div className="text-right">
                <RatingFilter 
                    rating={rating} 
                    setRating={setRating} 
                    style={{ 
                        marginBottom: 40, 
                        transform: 'scale(2) translateX(-97px)'                        
                    }}
                />
                </div>

                <RichtextEditor text={comment} setText={setComment} />  

                <button 
                    className="button"
                    type="submit"
                    style={{ marginTop: '40px' }}
                    disabled={!rating || !comment ? true : false}
                >
                    Submit    
                </button>                        
                    
            </form>  

        )}         
        </>
    )

}

export default Review
