import CheckIcon from "../icons/actions/CheckIcon"

const CheckoutSteps = ({ details, confirm, payment }) => {
   
    return (
        <div className="stepper-container">

            <div className="stepper">

                <span className="step active">
                    {!details 
                        ? <CheckIcon style={{ fontSize: '1.2rem' }} />
                        : '1'
                    }
                </span>

                <span className="line" />

                <span className={`step ${confirm || payment ? 'active' : ''}`}>
                    {confirm || details 
                        ? "2" 
                        : <CheckIcon style={{ fontSize: '1.2rem' }} />
                    }
                </span>           

                <span className="line" />

                <span className={`step ${payment ? 'active' : ''}`}>                   
                    3
                </span>            

            </div>

            <div className="stepper">
                
                <small style={{ color: details ? 'var(--icon-color)' : '#999' }}>Shipping</small>
            
                <small style={{ color: confirm ? 'var(--icon-color)' : '#999' }}>Confirm</small>
            
                <small style={{ color: payment ? 'var(--icon-color)' : '#999' }}>Payment</small>
            
            </div>
        </div>
    )

}

export default CheckoutSteps