import { useEffect, useRef, useState } from 'react'
import { adjustZero, handleTouchStartX, handleTouchStartY, handleTouchEndX, handleTouchEndY } from '../../../utils'
import ExpandLessIcon         from '../../layout/icons/arrows/ExpandLessIcon'
import ExpandMoreIcon         from '../../layout/icons/arrows/ExpandMoreIcon'
import KeyboardArrowLeftIcon  from '../../layout/icons/arrows/KeyboardArrowLeftIcon'
import KeyboardArrowRightIcon from '../../layout/icons/arrows/KeyboardArrowRightIcon'
import './thumbnails.css'

const Thumbnails = ({ images, currentIndex, handleThumb, isDockedRight, shouldMatchSlide, setShouldMatchSlide }) => {

  const containerRef = useRef(null)
  const sliderRef    = useRef(null)
  const startYRef    = useRef(0)
  const startXRef    = useRef(0)
   
  const [ containerWidth,  setContainerWidth  ] = useState(0)       
  const [ containerHeight, setContainerHeight ] = useState(0)         
  const [ thumbSize,       setThumbSize       ] = useState(0)          
  const [ leftPosition,    setLeftPosition    ] = useState(0)  
  const [ topPosition,     setTopPosition     ] = useState(0)  
  const [ disabled,        setDisabled        ] = useState(false)
  const [ willFit,         setWillFit         ] = useState(false)  
  
  useEffect(() => {  
    const slider    = sliderRef.current
    const container = containerRef.current
    if (!slider || !container) return
    const targetOne = document.querySelector('.lightbox-thumbs-container button:last-of-type')
    const targetTwo = document.querySelector('.lightbox-thumbs-container button')       
    const handleResize = () => {      
      setContainerWidth(container.offsetWidth)
      setContainerHeight(container.offsetHeight)  
      setThumbSize(slider.firstElementChild.offsetWidth + 5) 
      setShouldMatchSlide(true)
    }     
    handleResize()
    const handleStart = (e) => {
      isDockedRight 
        ? handleTouchStartY(e, startYRef) 
        : handleTouchStartX(e, startXRef)
    }      
    const handleEnd = (e) => {
      isDockedRight
        ? handleTouchEndY(e, startYRef, targetOne, targetTwo)
        : handleTouchEndX(e, startXRef, targetOne, targetTwo)
    }
    const handleWheel = (e) => {
      if (isDockedRight) {
          handleTouchEndY(e, startYRef, targetOne, targetTwo)
      } else {
          handleTouchEndX(e, startXRef, targetOne, targetTwo)
      }
    }
    slider.addEventListener('touchstart', handleStart)
    slider.addEventListener('touchend', handleEnd)
    slider.addEventListener('wheel', handleWheel)
    window.addEventListener('resize', handleResize)
    return () => {      
      slider.removeEventListener('touchstart', handleStart)
      slider.removeEventListener('touchend', handleEnd)  
      slider.removeEventListener('wheel', handleWheel)
      window.removeEventListener('resize', handleResize)
    }  
  }, [isDockedRight, setShouldMatchSlide, willFit])    

  useEffect(() => { 
    const checkThumbnailFit = () => { 
      const slider = sliderRef.current
      if (!slider) return
      const spaceRequired = thumbSize * images.length      
      setWillFit(
        isDockedRight          
          ? spaceRequired <= containerHeight
          : spaceRequired <= containerWidth
      )
      setDisabled(
        isDockedRight 
          ? Math.abs(topPosition)  >= (slider.offsetHeight - containerHeight) - 5
          : Math.abs(leftPosition) >= (slider.offsetWidth  - containerWidth) - 5
      )  
    }
    checkThumbnailFit() 
  }, [isDockedRight, containerWidth, containerHeight, leftPosition, topPosition,thumbSize,  images])

  useEffect(() => {    
    const slider = sliderRef.current    
    if (!slider || !shouldMatchSlide) return 
    if (willFit) {
      isDockedRight 
        ? setTopPosition(0) 
        : setLeftPosition(0)
      return
    }     
    const numOfSlidesThatFit = isDockedRight
      ? Math.floor(containerHeight / thumbSize)
      : Math.floor(containerWidth  / thumbSize)      
    const gap = isDockedRight
      ? containerHeight - (numOfSlidesThatFit * thumbSize)  
      : containerWidth  - (numOfSlidesThatFit * thumbSize)   
    const maxPosition = (images.length - numOfSlidesThatFit) * -thumbSize + gap 
    const newPosition =
    currentIndex >= images.length - numOfSlidesThatFit
      ? maxPosition
      : currentIndex * -thumbSize
    isDockedRight 
      ? setTopPosition(newPosition) 
      : setLeftPosition(newPosition)  
    if (newPosition === maxPosition) setDisabled(true)
  }, [isDockedRight, containerWidth, containerHeight, leftPosition, topPosition, thumbSize, images, shouldMatchSlide, currentIndex, willFit])
 
  const moveSlide = (dir) => {
    setShouldMatchSlide(false)
    const numOfSlidesThatFit = isDockedRight
      ? Math.floor(containerHeight / thumbSize)
      : Math.floor(containerWidth  / thumbSize)  
    const pastSlides = isDockedRight
      ? Math.abs(topPosition)  / thumbSize
      : Math.abs(leftPosition) / thumbSize  
    const numOfSlidesLeft = images.length - pastSlides - numOfSlidesThatFit  
    const moveBy = Math.min(numOfSlidesLeft * thumbSize, numOfSlidesThatFit * thumbSize)
    const maxPosition = isDockedRight
      ? sliderRef.current.offsetHeight - containerHeight
      : sliderRef.current.offsetWidth  - containerWidth

    if (dir === 'right') {
      const newPosition = adjustZero(isDockedRight 
        ? topPosition  - moveBy 
        : leftPosition - moveBy)
      if (isDockedRight) {
        Math.abs(newPosition) <= maxPosition
          ? setTopPosition(newPosition)
          : setTopPosition(-maxPosition)
      } else {
        Math.abs(newPosition) <= maxPosition
          ? setLeftPosition(newPosition)
          : setLeftPosition(-maxPosition)     
      }
    } else {
      const moveBackBy = Math.min(pastSlides * thumbSize, numOfSlidesThatFit * thumbSize)
      const newPosition = adjustZero(isDockedRight 
        ? topPosition  + moveBackBy 
        : leftPosition + moveBackBy)  
      if (newPosition <= 0) {
        isDockedRight 
          ? setTopPosition(newPosition) 
          : setLeftPosition(newPosition)        
      } else {
        isDockedRight 
          ? setTopPosition(0) 
          : setLeftPosition(0)         
      }
    }
  }   
  
  return (
      <>    
      <div className="lightbox-thumbs-container">

        {!willFit && (          
          <button
            onClick={() => moveSlide('left')}
            disabled={isDockedRight ? topPosition === 0 : leftPosition === 0}
          >
            {isDockedRight 
              ? <ExpandLessIcon /> 
              : <KeyboardArrowLeftIcon />
            }
          </button>
        )}            

        <div 
          ref={containerRef} 
          className="lightbox-thumbs-stage"
          style={{ justifyContent: willFit ? 'center' : 'flex-start' }}            
        >

          <div 
            ref={sliderRef}
            className="lightbox-thumbs-slider"
            style={{ 
              transform: isDockedRight 
                ? `translateY(${topPosition}px)` 
                : `translateX(${leftPosition}px)`,
                position: willFit ? 'relative' : 'absolute',                  
                justifyContent: willFit ? 'center' : 'flex-start',                  
            }}
          >
            {images.map((image, index) => (                       
              <img
                key={index} 
                src={image.thumbUrl}
                alt={image.title}
                onClick={() => handleThumb(index)}
                className={index === currentIndex ? 'active' : ''}                        
              />                    
            ))}
          </div>
          
        </div> 

        {!willFit && (          
          <button
            onClick={() => moveSlide('right')}
            disabled={disabled}
          >
            {isDockedRight 
              ? <ExpandMoreIcon /> 
              : <KeyboardArrowRightIcon />
            }
          </button>
        )} 

      </div>
      </>
  )

}

export default Thumbnails