const ArrowDropDownIcon  = ({ style, aria }) => {

    return (

        <svg style={style} aria-label={aria} className="icon" viewBox="0 0 24 24">
            <path d="m7 10 5 5 5-5z" />
        </svg>

    )

}

export default ArrowDropDownIcon 