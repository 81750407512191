import { Fragment, useState } from 'react'
import { formatMyDateTime } from '../../utils'
import Review from '../modals/Review'
import Modal from '../modals/modal/Modal'
import Confirm from '../modals/Confirm'
import RichtextOutput from '../layout/richtext/RichtextOutput'
import EditOutlinedIcon from '../layout/icons/actions/EditOutlinedIcon'
import DeleteOutlineIcon from '../layout/icons/actions/DeleteOutlineIcon'
import Rating from '../layout/tools/Rating'
import LocalOfferIcon from '../layout/icons/navigation/LocalOfferIcon'

const ListReviews = ({ reviews, user, toggleModal, deleteReviewHandler }) => {
   
    const [ isModalVisible, setIsModalVisible ] = useState(false)
    const [ reviewId,       setReviewId       ] = useState('')

    return (
        <>
        <div>

            {reviews && reviews.map(review => (
                <Fragment key={review._id}>
                {review.visible && (

                    <div>                    
                            
                        <div>
                            <figure style={{ width: 48, height: 48 }}>                                                  
                                <img 
                                    alt={user ? user.name : 'avatar'} 
                                    src={review.avatar && review.avatar.url ? review.avatar.url : '/images/default-avatar.jpg'}   
                                    className="round object-fit"                                      
                                />
                            </figure>
                            <LocalOfferIcon style={{ fontSize: '1rem' }} />
                            &nbsp;
                            <small style={{ color: 'var(--footer-text-color)' }}>
                                by <b style={{ color: 'var(--foreground)' }}>
                                    {review.name}
                                </b> on&nbsp;
                                <span style={{ color: 'var(--foreground)' }}>
                                    {formatMyDateTime(review.reviewCreatedAt)}
                                </span>
                            </small>
                            {user && user._id === review.user && (                        
                                <div className="d-flex" style={{ marginTop: 10 }}>
                                    <button
                                        className="icon-button" 
                                        onClick={() => {toggleModal(
                                            <Review rating={review.rating} comment={review.comment} />
                                        )}}
                                    >
                                        <EditOutlinedIcon />
                                    </button>    
                                    
                                    <button 
                                        className="icon-button"
                                        onClick={() => {
                                            setIsModalVisible(!isModalVisible)
                                            setReviewId(review._id)
                                        }}
                                    >
                                        <DeleteOutlineIcon />
                                    </button>
                                </div>                    
                            )}
                        </div>

                        <div style={{ margin: '10px 0 20px 0', pointerEvents: 'none' }}>
                            <Rating ratings={review.rating} />
                        </div>

                        <div 
                            className="comment" 
                            style={{ marginBottom: 20, background: 'var(--card-background)', borderRadius: 25, padding: 10 }}
                        >
                            <RichtextOutput text={review.comment} />
                        </div>

                    </div>
                    
                )}
               
                </Fragment>
            ))}

        </div>

        <Modal
            isModalVisible={isModalVisible} 
            onBackdropClick={() => setIsModalVisible(!isModalVisible)}   
            content={
                <Confirm 
                    onBackdropClick={() => setIsModalVisible(!isModalVisible)} 
                    onConfirm={() => deleteReviewHandler(reviewId)} 
                    message="Delete review"
                />
            }
        />                
        </>        
    )

}

export default ListReviews
