import './circularProgress.css'

const CircularProgress = () => {

    return (

        <div className="circular-progress-outer">

            <div className="circular-progress-inner" />
            
        </div>

    )

}

export default CircularProgress