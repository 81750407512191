const SendIcon = ({ style, aria }) => {

    return (

        <svg className="icon" aria-label={aria} viewBox="0 0 24 24" style={style}>
            <path d="M2.01 21 23 12 2.01 3 2 10l15 2-15 2z" />
        </svg>

    )

}

export default SendIcon