import { useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { useSpring, animated } from 'react-spring'
import { useMediaQuery } from 'react-responsive'
import HomeIcon from './layout/icons/navigation/HomeIcon'
import ShoppingBasketIcon from './layout/icons/navigation/ShoppingBasketIcon'
import MenuIcon from './layout/icons/navigation/MenuIcon'
import MenuOpenIcon from './layout/icons/navigation/MenuOpenIcon'
import ArrowBackIcon from './layout/icons/arrows/ArrowBackIcon'
import PersonOutlineIcon from './layout/icons/people/PersonOutlineIcon'
import KeyIcon from './layout/icons/login/KeyIcon'
import LockIcon from './layout/icons/login/LockIcon'
import QuestionMarkIcon from './layout/icons/navigation/QuestionMarkIcon'
import GavelIcon from './layout/icons/navigation/GavelIcon'

const Sidebar = () => {

    const navigate = useNavigate()

    const { isAuthenticated } = useSelector( state => state.auth )  

    const [ isSidebarOpen, setSideBarOpen ] = useState(false)  
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' })    

    const sidebarAppear = useSpring({
        transform: isSidebarOpen ? 'translateX(0)' : 'translateX(-100%)'
    })
  
    const handleBack = () => {
        navigate(-1)
    }

    return (
        <div className="sticky" style={{ top: 70 }}>   
        <button 
            className="icon-button mobile-button float-r"
            onClick={() => {setSideBarOpen(!isSidebarOpen)}}
            style={{ zIndex: 1 }}
        >
            {isSidebarOpen 
                ? <MenuOpenIcon aria="menu open" /> 
                : <MenuIcon aria="menu" />
            }           

        </button>        

        {(isSidebarOpen || !isMobile) && (

            <animated.nav style={isMobile ? sidebarAppear : {}}>
            
                <ul>
                    <li onClick={handleBack}>
                        <button className="icon-button">
                            <ArrowBackIcon aria="back" />
                        </button>
                        &nbsp; Back
                    </li> 

                    <li>
                        <NavLink 
                            to="/" 
                            className={(navData) => (navData.isActive ? "active" : 'none')}
                        >
                            <button className="icon-button">
                                <HomeIcon aria="home" />
                            </button>
                            &nbsp; Home
                        </NavLink>
                    </li>  

                    {isAuthenticated && (
                        <>
                        <li>
                            <NavLink 
                                to="/orders/me" 
                                className={(navData) => (navData.isActive ? "active" : 'none')}
                            >
                                <button className="icon-button">
                                    <ShoppingBasketIcon aria="orders" />
                                </button>
                                &nbsp; Orders
                            </NavLink>
                        </li>  

                        <li>
                            <NavLink 
                                to="/me/update" 
                                className={(navData) => (navData.isActive ? "active" : 'none')}
                            >
                                <button className="icon-button">
                                    <PersonOutlineIcon aria="profile" />
                                </button>
                                &nbsp; Profile
                            </NavLink>
                        </li>  

                        <li>
                            <NavLink 
                                to="/password/update" 
                                className={(navData) => (navData.isActive ? "active" : 'none')}
                            >
                                <button className="icon-button">
                                    <KeyIcon aria="password" />
                                </button>
                                &nbsp; Password
                            </NavLink>
                        </li>  
                        </>
                    )}    

                    <li style={{ display: 'list-item' }}>
                        <NavLink 
                            to="/terms" 
                            className={(navData) => (navData.isActive ? "active" : 'none')}
                        >
                            <button className="icon-button">
                                <GavelIcon aria="terms and conditions" />
                            </button>
                            &nbsp; Terms & Conditions
                        </NavLink>
                    </li>
                    <li style={{ display: 'list-item' }}>
                        <NavLink 
                            to="/privacy"
                            className={(navData) => (navData.isActive ? "active" : 'none')}
                        >
                            <button className="icon-button">
                                <LockIcon aria="privacy" />
                            </button>
                            &nbsp; Privacy Policy
                        </NavLink>
                    </li>    
                    <li style={{ display: 'list-item' }}>
                        <NavLink 
                            to="/faqs"
                            className={(navData) => (navData.isActive ? "active" : 'none')}
                        >
                            <button className="icon-button">
                                <QuestionMarkIcon aria="faqs" />
                            </button>
                            &nbsp; FAQ's
                        </NavLink>
                    </li>             
        
                </ul>
        
            </animated.nav>

        )} 
        </div>
    )

}

export default Sidebar
