import { useEffect, useState } from 'react'
import './circularLoader.css'

const CircularLoader = ({ timing = 8000, resetTrigger  }) => {

    const [ progress, setProgress ] = useState(0)

    useEffect(() => {

        setProgress(0)
    
        const startTime = Date.now()
    
        const interval = setInterval(() => {
          const elapsedTime = Date.now() - startTime
          const newProgress = Math.min((elapsedTime / timing) * 100, 100)
    
          setProgress(newProgress)
    
          if (newProgress === 100) {
            clearInterval(interval)
          }
        }, 10)
    
        return () => clearInterval(interval)
      }, [resetTrigger, timing])

      const radius = 10
    const strokeWidth = 2
    const circumference = 2 * Math.PI * radius
    const strokeDashoffset = circumference - (progress / 100) * circumference

    return (

        <svg width="24" height="24" viewBox="0 0 24 24" className="circular-loader">
            <circle
                cx="12"
                cy="12"
                r={radius}
                fill="none"
                stroke="#666"
                strokeWidth={strokeWidth}
            />
            <circle
                cx="12"
                cy="12"
                r={radius}
                fill="none"
                stroke="white"
                strokeWidth={strokeWidth}
                strokeDasharray={circumference}
                strokeDashoffset={strokeDashoffset}
                transform="rotate(-90 12 12)" 
                style={{ transition: "stroke-dashoffset 0.1s linear" }}
            />
        </svg>

    )

}

export default CircularLoader