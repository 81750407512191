import { useEffect, useState } from 'react'
import { cloudinaryURL } from '../../../utils'
import AutorenewIcon from '../../layout/icons/play/AutorenewIcon'
import ArrowRightIcon from '../../layout/icons/arrows/ArrowRightIcon'
import ArrowLeftIcon from '../../layout/icons/arrows/ArrowLeftIcon'
import PlayArrowIcon from '../../layout/icons/play/PlayArrowIcon'
import PauseIcon from '../../layout/icons/play/PauseIcon'
import parse from 'html-react-parser'
import './slideCarousel.css'

const SlideCarousel = ({ data }) => {

    const [ isFlipped,  setIsFlipped  ] = useState(false)
    const [ imgIndex,   setImgIndex   ] = useState(0)     
    const [ animation,  setAnimation  ] = useState(true)     
    const [ left,       setLeft       ] = useState('calc(-100% / 3')
    const [ textLeft,   setTextLeft   ] = useState( '-200%' )
    const [ imageArray, setImageArray ] = useState([])  

    const cloneData = (data) => {
        
        let array = []
        array.push(data[data.length - 2])
        array.push(data[data.length - 1])
        data.forEach((d) => {            
            array.push(d)
        })          
        array.push(data[0])
        array.push(data[1])
        setImageArray(array)
    }

    const resetClasses = (elements, add) => {
        for ( let i = 0; i < elements.length; i++ ) {
            if ( add === true ) {
                elements[i].classList.add('transition')  
            } else {
                elements[i].classList.remove('transition') 
            }                                  
        }
    } 

    const handleMove = ( dir ) => {
        const item      = document.querySelector( '.carousel li' )
        const text      = document.querySelector( '.carousel-text li' )
        const slides    = document.querySelectorAll('.transition')
        const width     = item.offsetWidth * -1         
        const textwidth = text.offsetWidth * -1 
        if ( dir === 'left' ) {
            setImgIndex( imgIndex - 1 )
            setLeft    ( ( imgIndex  * width ) + 'px' )
            setTextLeft( ( imgIndex + 1 ) * textwidth + 'px' )
            if ( imgIndex === 0 ) {
                setTimeout(() => {
                    resetClasses(slides, false)  

                    setImgIndex( data.length - 1 )
                    setLeft    ( ( data.length * width ) + 'px' )
                    setTextLeft( ( data.length + 1 ) * textwidth + 'px' )

                    setTimeout(() => {
                        resetClasses(slides, true)
                    }, 50)   
                }, 500) 
            }
        } else {
            setImgIndex( imgIndex + 1 )
            setLeft    ( ( imgIndex + 2 ) * width + 'px' )
            setTextLeft( ( imgIndex + 3 ) * textwidth + 'px' )
            if ( imgIndex === (data.length - 1) ) {  
                setTimeout(() => {
                    resetClasses(slides, false)                  
                    setImgIndex( 0 )
                    setLeft    ( 'calc(-100% / 3' )
                    setTextLeft( '-200%' )
                    setTimeout(() => {
                        resetClasses(slides, true)
                    }, 50)   
                }, 500)  
            }            
        }       
    } 

    useEffect(() => {   

        cloneData(data)

        const resetPosition = () => {
            const item      = document.querySelector( '.carousel li' )
            const text      = document.querySelector( '.carousel-text li' )
            if (!item) return
            const width     = item.offsetWidth * -1         
            const textwidth = text.offsetWidth * -1             
            setLeft    ( (imgIndex + 1) * width + 'px' )
            setTextLeft( (imgIndex + 2) * textwidth + 'px' )            
        }
        window.addEventListener('resize', () => resetPosition())

    }, [ data, imgIndex ]) 
   
    return (    
        <div className="relative">

            <div className={`stage carousel ${animation && 'anim'}`}>

                <ul style={{ left: left }} className="transition">    

                    {imageArray && imageArray.map((slide, index) => (
                        <li 
                            key={index}
                            className={index === (imgIndex + 2) ? 'active' : ''}
                        > 
                            <div className={`${isFlipped ? "backTwo" : "back"}`}>
                        
                                <img
                                    src={cloudinaryURL(slide.frontImage)} 
                                    alt={slide.alt} 
                                    width="340" 
                                    height="660"
                                />

                            </div>

                             <div className={`${isFlipped ? "frontTwo" : "front"}`}>    
                               
                                <img
                                    src={cloudinaryURL(slide.backImage)} 
                                    alt={slide.alt} 
                                    width="340" 
                                    height="660"   
                                />
                                
                            </div>   
                        </li>
                    ))}                              
                </ul>

                <div className="controls">                    

                    <button 
                        className="icon-button" 
                        onClick={() => setAnimation(!animation)}
                        aria-label="pause"
                    >
                        {animation ? <PauseIcon /> : <PlayArrowIcon />}
                    </button>

                    <button 
                        className="icon-button" 
                        aria-label="flip"
                        onClick={() => setIsFlipped(!isFlipped)}>
                        <AutorenewIcon />
                    </button>                    
                    
                </div>

                <div 
                    className="controls"
                    style={{ top: '50%', transform: 'translateY(-50%)', width: '100%' }}
                >

                    <button 
                        className="icon-button" 
                        onClick={() => handleMove('left')} 
                        aria-label="left"
                    >
                        <ArrowLeftIcon />
                    </button> 

                    <button 
                        className="icon-button" 
                        aria-label="right"
                        onClick={() => handleMove('right')} 
                    >
                        <ArrowRightIcon />
                    </button>

                </div>

                <div className="carousel carousel-text">

                    <ul className="transition" style={{ left: textLeft }}> 

                        {imageArray && imageArray.map((slide, index) => (

                            <li key={index}>
                                <div>
                                    {slide.icon && parse(slide.icon)}
                                    {slide.name && slide.name}
                                </div>
                            </li>

                        ))}

                    </ul>

                </div>

            </div>               

        </div>
    )
  }
  
  export default SlideCarousel