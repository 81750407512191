import ToolTip from '../tooltip/ToolTip'
import './iconButton.css'

const IconButton = ({ className, aria, icon, toolTip, onClick, onMouseEnter, style, disabled }) => {

    return (
        <>
        {toolTip 
            ? 
            <ToolTip title={toolTip}>

            <button 
                className={`icon-button ${className}`}
                aria-label={aria}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                style={style}
                disabled={disabled}
            >
                {icon}

            </button>            
            
        </ToolTip>
        : 
        <button 
            className={`icon-button ${className}`}
            aria-label={aria}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            style={style}
            disabled={disabled}
        >
            {icon}

        </button>
        }
        </>
    )

}

export default IconButton