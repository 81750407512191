const Parallax = ({ url }) => {

  return (

    <div 
        className="background-cover parallax" 
        style={{ 
          height: '100vh', 
          backgroundImage: `url(${url})`,   
          backgroundAttachment: 'fixed',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          // backgroundSize: 'cover'         
        }} 
    />

  )

}

export default Parallax