const StructuredData = ({ title, description, thumb, image, date, category, rating, numOfReviews }) => {

    const text = description.replace(/(<([^>]+)>)/gi, "").substring(0, 155) + '...'

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": title,
        "brand": "Marine Graphics",
        "material": "PVC",
        "description": text,
        "image": image,
        "category": category,
        "thumbnailUrl": thumb,
        "datePublished": new Date(date).getFullYear(),
        "review": {
          "@type": "Review",
          "reviewRating": {
            "@type": "Rating",
            "ratingValue": rating,
            "bestRating": 5
          },
          "author": {
            "@type": "Person",
            "name": "Nicholas Dittmer"
          }
        },
        "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": rating,
            "reviewCount": numOfReviews
        },         
    }

  return (
    <script type="application/ld+json">
        {JSON.stringify(structuredData)}
    </script>
  )

}

export default StructuredData
