import { useState } from 'react'
import VisibilityOffIcon from '../icons/visibility/VisibilityOffIcon'
import VisibilityIcon from '../icons/visibility/VisibilityIcon'
import CalendarMonthIcon from '../icons/navigation/CalendarMonthIcon'
import './forms.css'

const Input = ({ type, readonly, name, value, onChange, onBlur, placeholder, required, rows, filled }) => {

    const [ passwordVisible, setPasswordVisible ] = useState()

    const togglePassword = (e) => {
        e.preventDefault() 
        setPasswordVisible(!passwordVisible)
    } 

    return (
        <>
        {type === 'text' && (
            <label className="label">                           

                <input 
                    name={name}
                    value={value}
                    onChange={onChange}
                    className="input"
                    placeholder={placeholder}
                    style={{ 
                        background: filled 
                            ? 'var(--cta-discount)' 
                            : required
                                ? 'var(--cta-required)'
                                : 'transparent'
                    }}
                    readOnly={readonly}
                />

                <small>{placeholder}</small>

            </label> 
        )}

        {type === 'date' && (
            <label className="label">                           

                <input 
                    name={name}
                    value={value}
                    onChange={onChange}
                    className="input"
                    placeholder={placeholder}
                    style={{ 
                        background: filled 
                            ? 'var(--cta-discount)' 
                            : required
                                ? 'var(--cta-required)'
                                : 'transparent'
                    }}
                    readOnly
                />

                <small>{placeholder}</small>

                <span className="adornment-end">
            
                    <CalendarMonthIcon />                            

                </span>

            </label> 
        )}

        {type === 'number' && (
            <label className="label">                           

                <input 
                    type="number"
                    name={name}
                    value={value}
                    onChange={onChange}
                    className="input"
                    placeholder={placeholder}
                    style={{ 
                        background: filled 
                            ? 'var(--cta-discount)' 
                            : required
                                ? 'var(--cta-required)'
                                : 'transparent'
                    }}
                />

                <small>{placeholder}</small>

            </label> 
        )}

        {type === 'password' && (
            <label className="label">                           

                <input 
                    name={name}
                    type={passwordVisible ? 'text' : 'password'}
                    value={value}
                    onChange={onChange}
                    className="input"
                    placeholder={placeholder}
                    style={{ 
                        background: filled 
                            ? 'var(--cta-discount)' 
                            : required
                                ? 'var(--cta-required)'
                                : 'transparent'
                    }}
                />

                <small>Password</small>

                <span className="adornment-end">

                    <button 
                        className="icon-button"
                        onClick={togglePassword}
                    >
                        {passwordVisible 
                            ? <VisibilityIcon /> 
                            : <VisibilityOffIcon />                            
                        }
                    </button>

                </span>

            </label> 
        )}

        {type==="textarea" && (
            <label className="label">  

                <textarea 
                    name={name}
                    value={value}
                    onChange={onChange}
                    className="input"
                    placeholder={placeholder}
                    style={{ 
                        background: filled 
                            ? 'var(--cta-discount)' 
                            : required
                                ? 'var(--cta-required)'
                                : 'transparent'
                    }}
                    rows={rows}
                />            

                <small>{placeholder}</small>

            </label>
        )}
        </>
    )

}

export default Input