import { useEffect, useState } from 'react'
import ToolTip from '../tools/ToolTip'

const ModeToggle = () => {    

    const [ darkMode, setDarkMode ] = useState(localStorage.getItem('darkMode'))

    useEffect(() => {
        let mode = localStorage.getItem('darkMode') 
        if (mode) {            
            setDarkMode(true)
            document.body.classList.add('darkmode')
        } else {
            setDarkMode(false)           
        }          
    }, [])

    const toggleMode = () => {        
        if (!darkMode) {
            setDarkMode(true)
            document.body.classList.add('darkmode')
            localStorage.setItem('darkMode', 'enabled')
        } else {
            setDarkMode(false)
            document.body.classList.remove('darkmode')
            localStorage.removeItem('darkMode')
        }       
    }

    return (        
        <ToolTip 
            title={darkMode ? 'Light mode' : 'Dark mode'} 
            position="left"
        >
            <label className="toggle">
                <input  onChange={toggleMode} className="toggle-input" type="checkbox"  />
                <div className="toggle-fill" />
            </label>
        </ToolTip>
    )

}

export default ModeToggle