const CheckIcon = ({ style, aria }) => {

    return (

        <svg 
            aria-label={aria} 
            className="icon" 
            viewBox="0 0 24 24"
            style={style}
        >
            <path d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
        </svg>

    )

}

export default CheckIcon