import LinearProgress from '../forms/LinearProgress'

const Loader = ({ position }) => {

    return (

        <>
        <LinearProgress />

        <div style={{
            position: position ? position : 'fixed',
            width: '100%',
            height: position && position === 'relative' ? '100%' : 'calc(100vh - 58px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}> 

            <svg 
                className="animated-svg" 
                focusable="false" 
                aria-hidden="true" 
                viewBox="0 0 24 24" 
                width="200" 
                height="200"
            >             

                <path className="path draw" d="m17 15 1.55 1.55c-.96 1.69-3.33 3.04-5.55 3.37V11h3V9h-3V7.82C14.16 7.4 15 6.3 15 5c0-1.65-1.35-3-3-3S9 3.35 9 5c0 1.3.84 2.4 2 2.82V9H8v2h3v8.92c-2.22-.33-4.59-1.68-5.55-3.37L7 15l-4-3v3c0 3.88 4.92 7 9 7s9-3.12 9-7v-3l-4 3zM12 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1z" />
                
            </svg>

        </div>
        </>

    )
    
}

export default Loader