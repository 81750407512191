const DockOnRightIcon = ({ style, aria }) => {

    return (

        <svg style={style} aria-label={aria} className="icon" viewBox="0 0 24 24">            
            <rect
                x="3"
                y="3"
                width="18"
                height="18"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
            />           
            <rect
                x="15"
                y="3"
                width="6"
                height="18"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
            />
        </svg>

    )
}

export default DockOnRightIcon