const ArrowForwardIcon = ({ style, aria }) => {

    return (

        <svg style={style} aria-label={aria} className="icon" viewBox="0 0 24 24">
            <path d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
        </svg>

    )

}

export default ArrowForwardIcon