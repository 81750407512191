import Select from "../layout/forms/Select"

const Filter = ({ filter, setFilter }) => {

    const data = [
        {
            label: 'Latest',
            value: 'Latest'
        },
        {
            label: 'Most Popular',
            value: 'Most Popular'
        },
        {
            label: 'Price High to Low',
            value: 'Price High to Low'
        },
        {
            label: 'Price Low to High',
            value: 'Price Low to High'
        },
    ]

  return (

    <Select
        type="regular"
        label="Sort"
        data={data} 
        value={filter}
        onChange={setFilter} 
    /> 

  )

}

export default Filter