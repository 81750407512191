import { useState } from 'react'
import Backdrop from '../tools/Backdrop'
import ArrowDropDownIcon from '../icons/arrows/ArrowDropDownIcon'
import ArrowDropUpIcon from '../icons/arrows/ArrowDropUpIcon'
import EditOutlinedIcon from '../icons/actions/EditOutlinedIcon'
import DeleteOutlineIcon from '../icons/actions/DeleteOutlineIcon'
import './forms.css'

function Select({ type, label, data, value, valueTwo, onChange, onChangeTwo, onChangeThree, func, setId, setConfirmOpen, confirmOpen, updateCatOpen, setUpdateCatOpen, filled, required }) {  

    const [ isOpen, setIsOpen ] = useState(false)  

    const handleSelectChange = (e) => {
        onChange(e.target.value)  
        setIsOpen(false)     
        if (func) {
            func(e) 
        }    
    }

    const handleMultiSelectChange = (e) => {
        onChange(e.target[0].value)
        onChangeTwo(e.target[1].value)
        onChangeThree(e.target[2].value)
        if (func) {
            func(e) 
        } 
        setIsOpen(false)    
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }  

    return (
        <>
        {isOpen && (
            <Backdrop
                invisible
                onclick={toggleDropdown}
            /> 
        )}
        
        <div className={`select-container relative`}>
            <div 
                className={`label ${isOpen ? 'open' : ''}`} 
                onClick={toggleDropdown}
            >
                <div 
                    tabIndex={0} 
                    className="input focusable-div"
                    style={{ 
                        background: filled 
                            ? 'var(--cta-discount)' 
                            : required
                                ? 'var(--cta-required)'
                                : 'transparent'
                    }}
                >
                    <div className="d-flex align-items-center">
                        {type === 'shipping' && (
                            <img 
                                src={valueTwo} 
                                alt={value} 
                                style={{ width: 20, marginRight: 10 }}
                            />
                        )}                    
                    
                        {value || label}
                    </div>
                </div>               

                <small>{label}</small>
                
                <span className="adornment-end">                   
                    {isOpen 
                        ? <ArrowDropUpIcon /> 
                        : <ArrowDropDownIcon />                            
                    }
                </span>

            </div>

            {isOpen && (                
                <>    
                {type === 'regular' && (
                    <ul className="select-dropdown">
                        {data && data.map((item, index) => (
                            <li 
                                key={index} 
                                onClick={() => handleSelectChange({ target: { value: item.value } })}
                            >
                                {item.label}
                            </li>
                        ))}
                    </ul>
                )}

                {type === 'category' && (
                    <ul className="select-dropdown">
                        {data && data.map((item, index) => (
                            <li 
                                key={index} 
                                className="d-flex align-items-center justify-content-between"                                
                            >
                                <span 
                                    className="select-label"
                                    onClick={() => handleSelectChange({ target: { value: item.value } })}
                                >
                                    {item.label}
                                </span>
                                <span className="d-flex">
                                    <span 
                                        className="icon-button"
                                        onClick={() => {
                                            setUpdateCatOpen(!updateCatOpen)
                                            setId(item.id)
                                        }}
                                    >
                                        <EditOutlinedIcon />
                                    </span>
                                    &nbsp;
                                    <span 
                                        className="icon-button"
                                        onClick={() => {
                                            setConfirmOpen(!confirmOpen)
                                            setId(item.id)
                                        }}
                                    >
                                        <DeleteOutlineIcon />
                                    </span>
                                </span>
                            </li>
                        ))}
                    </ul>
                )}

                {type === 'shipping' && (
                    <ul className="select-dropdown">
                        {data && data.map((item, index) => (
                            <li 
                                key={index} 
                                className="d-flex align-items-center"
                                onClick={() => handleMultiSelectChange({ target: [{ value: item.value }, { value: item.iconUrl }, { value: item.trackingLink }] })}
                            >
                                <img 
                                    src={item.iconUrl} 
                                    alt={item.label} 
                                    style={{ width: 20, marginRight: 10 }}
                                />
                                {item.label}
                            </li>
                        ))}
                    </ul>
                )}
                </>
            )}
        </div>       
        </>
    )
}

export default Select
