const PlayArrowIcon = ({ style, aria }) => {

    return (

        <svg style={style} aria-label={aria} className="icon" viewBox="0 0 24 24">
            <path d="M8 5v14l11-7z" />
        </svg>

    )

}

export default PlayArrowIcon