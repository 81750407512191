import ArrowBackIcon from '../layout/icons/arrows/ArrowBackIcon'
import CheckoutSteps from '../layout/tools/CheckoutSteps'
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js'
import { useDispatch, useSelector } from 'react-redux'
import { useAlert } from 'react-alert'
import { useEffect, useState } from 'react'
import { createOrder, clearErrors } from '../../actions/orderActions'
import { emptyCart } from '../../actions/cartActions'
import { formatPrice } from '../../utils'
import { CREATE_ORDER_RESET } from '../../constants/orderConstants'
import CircularProgress from '../layout/tools/circularProgress/CircularProgress'
import axios from 'axios'
import LockIcon from '../layout/icons/login/LockIcon'

const options = {
    style: {
        base: {
            fontSize: "14px"
        },
        invalid: {
            color: "red"
        }
    }
}

const Stripe = ({ setIsStripeOpen, setIsPaymentOpen, setIsSuccessOpen }) => {
   
    const dispatch = useDispatch()
    const alert    = useAlert()      
    const stripe   = useStripe()
    const elements = useElements()   

    const { user                    } = useSelector( state => state.auth ) 
    const { error, order: newOrder  } = useSelector( state => state.newOrder )
    const { cartItems, shippingInfo } = useSelector( state => state.cart )

    const [ loading, setLoading ] = useState(false)
    const [ isReady, setIsReady ] = useState(false)

    useEffect(() => {
        if(error) {
            alert.error(error)
            dispatch(clearErrors())
        }
    }, [dispatch, alert, error])

    useEffect(() => {
        const sendEmail = () => {             
            
            const formData = new FormData()
            formData.set('id',             newOrder.order._id)   
            formData.set('address',        newOrder.order.shippingInfo.address) 
            formData.set('city',           newOrder.order.shippingInfo.city) 
            formData.set('subDistrict',    newOrder.order.shippingInfo.subDistrict) 
            formData.set('district',       newOrder.order.shippingInfo.district) 
            formData.set('postalCode',     newOrder.order.shippingInfo.postalCode) 
            formData.set('country',        newOrder.order.shippingInfo.country) 
            formData.set('countryCode',    newOrder.order.shippingInfo.countryCode) 
            formData.set('email',          newOrder.order.shippingInfo.email) 
            formData.set('name',           newOrder.order.shippingInfo.name) 
            formData.set('phone',          newOrder.order.shippingInfo.phoneNo)      
            formData.set('itemsPrice',     newOrder.order.itemsPrice) 
            formData.set('shippingPrice',  newOrder.order.shippingPrice) 
            formData.set('totalPrice',     newOrder.order.totalPrice) 
            formData.set('discount',       newOrder.order.discount) 
            formData.set('createdAt',      newOrder.order.createdAt) 
            formData.set('orderStatus',    newOrder.order.orderStatus)                
            formData.set('paymentId',      newOrder.order.paymentInfo.id)                
            formData.set('paymentType',    newOrder.order.paymentInfo.type)                
            formData.set('paymentStatus',  newOrder.order.paymentInfo.status)                
            newOrder.order.orderItems.forEach(item => {
                formData.append('items', JSON.stringify(item))
            })      
    
            axios.post( '/api/v1/booking', formData )
                .then(res => {  
                    dispatch(emptyCart())
                    setIsStripeOpen(false)  
                    setIsSuccessOpen(true)   
                    setIsReady(false)  
                }).catch((res) => {  
                    console.log(res)
                })   
        }
        if (isReady && newOrder) {
            sendEmail()
            dispatch({ type: CREATE_ORDER_RESET })
            setIsReady(false)  
        }
    }, [dispatch, setIsStripeOpen, setIsSuccessOpen, newOrder, isReady])      

    const order = {
        orderItems: cartItems,
        shippingInfo
    }

    const orderInfo = JSON.parse(sessionStorage.getItem('orderInfo'))

    if(orderInfo) {
        order.itemsPrice    = orderInfo.itemsPrice
        order.shippingPrice = shippingInfo.country === 'Thailand' ? 0 : orderInfo.shippingPrice
        order.taxPrice      = orderInfo.taxPrice
        order.totalPrice    = orderInfo.totalPrice
        order.discount      = orderInfo.discount
    }

    const paymentData = {
        amount: Math.round(orderInfo.totalPrice * 100)
    }

    const submitHandler = async (e) => {   
        e.preventDefault()
        setLoading(true)        
        let res
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            res = await axios.post('/api/v1/stripe/process', paymentData, config)

            const clientSecret = res.data.client_secret
            
            if(!stripe || !elements) { return }

            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardNumberElement),
                    billing_details: {
                        name: user.name,
                        email: user.email
                    }
                }
            })

            if(result.error) {

                alert.error(result.error.message)
                setLoading(false)   

            } else {
                if(result.paymentIntent.status === 'succeeded') {                    
                    order.paymentInfo = {
                        id: result.paymentIntent.id,
                        type: 'Stripe',
                        status: 'COMPLETED'
                    }  
                    dispatch(createOrder(order))  
                    setLoading(false)   
                    setIsReady(true)      
                } else {
                    alert.error('There was an issue while processing payment')
                    setLoading(false)    
                }
            }

        } catch (error) {    
            setLoading(false)      
            alert.error(error.response.data.message)
        }
    }
    
    const handleBack = () => {        
        setIsPaymentOpen(true)
        setIsStripeOpen(false)  
    }

    return (
        <>
        <button 
            className="icon-button" 
            aria-label="back"
            onClick={handleBack}
            style={{ margin: '5px 0 0 5px' }}
        >
            <ArrowBackIcon />

        </button>   

        <CheckoutSteps payment />   

        <form className="wrapper">    

            {process.env.REACT_APP_NODE_ENV === 'DEVELOPMENT' && (
                <>
                <small>4242 4242 4242 4242</small>
                <br />
                <br />
                </>
            )}   
            
            <h6>Card Number</h6>                        
            <br />   
            <CardNumberElement options={options} className="input" />   

            <br />
            <h6>Card Expiry</h6>
            <br />                                                                    
            <CardExpiryElement options={options} className="input" />   

            <br />
            <h6>Card CVC</h6>
            <br />                                                                    
            <CardCvcElement options={options} className="input" />  

            {loading ? (
                <div className="d-flex justify-content-center" style={{ marginTop: 40 }}>
                    <CircularProgress />
                </div>
            ) : (
                <button 
                    className="button" 
                    onClick={submitHandler}
                    style={{ marginTop: 40 }}
                    disabled={loading ? true: false}
                >
                    Pay&nbsp;
                    {orderInfo && formatPrice(orderInfo.totalPrice)}
                </button>    
            )}

            <div className="text-center" style={{ marginTop: 80 }}>
                <LockIcon style={{ fontSize: '8rem', opacity: '0.1' }} />
            </div>           

        </form>
        </>
    )

}

export default Stripe