import './circularProgress.css'

const CircularProgress = () => {

    return (

        <div className="circular-progress">
            <div className="progress" />
        </div>

    )

}

export default CircularProgress