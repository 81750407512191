const ExpandMoreIcon = ({ style, aria }) => {

    return (

        <svg className="icon" viewBox="0 0 24 24" style={style} aria-label={aria}>
            <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z" />
        </svg>

    )

}

export default ExpandMoreIcon