import { Link } from 'react-router-dom'
import ScrollToTop from 'react-scroll-to-top'
import CookieConsent from 'react-cookie-consent'
import ArrowCircleUpIcon from '../icons/arrows/ArrowCircleUpIcon'
import FacebookRoundedIcon from '../icons/social/FacebookRoundedIcon'
import YouTubeIcon from '../icons/social/YouTubeIcon'
import InstagramIcon from '../icons/social/InstagramIcon'
import LockIcon from '../icons/login/LockIcon'
import QuestionMarkIcon from '../icons/navigation/QuestionMarkIcon'
import GavelIcon from '../icons/navigation/GavelIcon'
import ToolTip from '../tools/ToolTip'
import './footer.css'

const partners = [
    {
        name: 'West Coast Divers',
        img: '/images/partners/West_Coast_Divers68875.png',
        url: 'https://www.westcoastdivers.com/'
    },
    {
        name: 'Koh Tao Dive Supply',
        img: '/images/partners/Koh_Tao_Dive_Supply.png',
        url: 'http://www.kohtaodivesupply.com/about.html'
    },
    {
        name: 'Aussie Divers Phuket',
        img: '/images/partners/Aussie_Divers_Phuket.png',
        url: 'https://www.aussiediversphuket.com/'
    },
    {
        name: 'Lanta Divers',
        img: '/images/partners/Lanta_Divers.png',
        url: 'https://lantadiver.com/'
    },
    {
        name: 'The Adventure Club',
        img: '/images/partners/The_Adventure_Club.png',
        url: 'https://www.diving-in-thailand.net/'
    },
    {
        name: 'IQ Dive',
        img: '/images/partners/IQ_Dive.png',
        url: 'https://www.iq-dive.com/'
    },
    {
        name: 'Kon Tiki Dive',
        img: '/images/partners/Kon_Tiki_Dive.png',
        url: 'https://kontiki-krabi.com/index.php/en/'
    },
    {
        name: 'Oceanic Dive Center',
        img: '/images/partners/Oceanic_Dive_Center.png',
        url: 'https://www.oceanicdivecenter.com/'
    },
    {
        name: 'One Stop Dive Center',
        img: '/images/partners/One_Stop_Dive_Center.png',
        url: 'https://onestopdive.com/'
    },
    {
        name: 'Dive Subculture',
        img: '/images/partners/Dive_Subculture.png',
        url: 'https://www.divesubculture.com/fbclidIwAR01Jbxwy1drVvdQ69r5xywm0D3hHYwnIlPe-77OiIgLMwlgXTNgjJk7NuE'
    },
]

const Footer = () => {  

    return (
        <>
        <footer>               

            <div className="wrapper">

                <div className="parent gap-4">
                    <div>
                        <h3>Payment Options</h3>

                        <div className="d-flex gap-2">

                            <ToolTip title="PayPal">
                                <a href="https://www.paypal.com/" target="_blank" rel="noreferrer">
                                    <img 
                                        src="https://res.cloudinary.com/marine-graphics/image/upload/v1688108126/fishslates/couriers/paypal_pstkh1.png"                       
                                        alt="paypal" 
                                        width="48"
                                        height="48"
                                        style={{ width: 48 }} 
                                        loading="lazy"                            
                                    />
                                </a>   
                            </ToolTip>  

                            <ToolTip title="Stripe">
                                <a href="https://www.stripe.com/" target="_blank" rel="noreferrer">
                                    <img 
                                        src="https://res.cloudinary.com/marine-graphics/image/upload/v1693511567/fishslates/couriers/stripe_w6jmvx.png" 
                                        alt="Kasikornbank"
                                        width="48"
                                        height="48"
                                        style={{ width: 48 }} 
                                        loading="lazy"
                                    />
                                </a>
                            </ToolTip>                       
                            
                            <ToolTip title="KasikornBank">
                                <a href="https://www.kasikornbank.com/en/personal" target="_blank" rel="noreferrer">
                                    <img 
                                        src="https://res.cloudinary.com/marine-graphics/image/upload/v1688108127/fishslates/couriers/kbank_ppab2d.png" 
                                        alt="Kasikornbank"
                                        width="48"
                                        height="48"
                                        style={{ width: 48 }} 
                                        loading="lazy"
                                    />
                                </a>
                            </ToolTip>
                        </div>
                    </div>
                    <div>
                        <h3 className="text-right">Delivery Services</h3>

                        <div className="d-flex gap-2">

                            <ToolTip title="DHL">
                                <a href="https://www.dhl.com/th-en/home/tracking.html" target="_blank" rel="noreferrer">
                                    <img src="https://res.cloudinary.com/marine-graphics/image/upload/v1688031517/fishslates/couriers/dhl_yleotq.png"                    
                                        alt="dhl"
                                        width="48"
                                        height="48"
                                        style={{ width: 48 }} 
                                        loading="lazy"
                                    />
                                </a> 
                            </ToolTip>
                            <ToolTip title="UPS">
                                <a href="https://www.ups.com/track?loc=en_TH&requester=ST/" target="_blank" rel="noreferrer">
                                    <img src="https://res.cloudinary.com/marine-graphics/image/upload/v1688031517/fishslates/couriers/ups_tujinc.png"                  
                                        alt="ups"
                                        width="48"
                                        height="48"
                                        style={{ width: 48 }} 
                                        loading="lazy"
                                    />                            
                                </a>
                            </ToolTip>
                            <ToolTip title="EMS">
                                <a href="https://track.thailandpost.co.th/?lang=en" target="_blank" rel="noreferrer">
                                    <img src="https://res.cloudinary.com/marine-graphics/image/upload/v1688031519/fishslates/couriers/ems_dcssey.png"                 
                                        alt="ems"
                                        width="48"
                                        height="48"
                                        style={{ width: 48 }}
                                        loading="lazy"
                                    />
                                </a> 
                            </ToolTip>
                            <ToolTip title="Thailand Post">    
                                <a href="https://track.thailandpost.co.th/?lang=en" target="_blank" rel="noreferrer">
                                    <img src="https://res.cloudinary.com/marine-graphics/image/upload/v1688031517/fishslates/couriers/thailand-post_bhfq0f.png"                       
                                        alt="thai post"
                                        width="48"
                                        height="48"
                                        style={{ width: 48 }} 
                                        loading="lazy"
                                    />
                                </a>
                            </ToolTip>

                        </div>

                    </div>
                </div>

            </div>            

            <hr />

            <div style={{ marginBottom: 40 }}>

                <h4 className="text-center">Follow Us</h4>

                <div className="d-flex gap-2 justify-content-center">

                    <ToolTip title="Facebook">
                    <a href="https://www.facebook.com/fishslates" target="_blank" rel="noreferrer" aria-label="Facebook">
                        <FacebookRoundedIcon style={{ fontSize: '3rem' }} />
                    </a>
                    </ToolTip>
                    <ToolTip title="Coming soon ...">
                    {/* <a href="https://www.facebook.com/" target="_blank" rel="noreferrer" aria-label="YouTube"> */}
                        <YouTubeIcon style={{ fontSize: '3rem' }} />
                    {/* </a> */}
                    </ToolTip>
                    <ToolTip title="Coming soon ...">
                    {/* <a href="https://www.facebook.com/" target="_blank" rel="noreferrer" aria-label="Instagram"> */}
                        <InstagramIcon style={{ fontSize: '3rem' }} />
                    {/* </a> */}
                    </ToolTip>

                </div>

            </div>

            <div className="wrapper" style={{ background: 'var(--input-background-color)' }}>
      
                <h4 className="text-center">Partners</h4>
    
                <div className="partners">  

                    {partners && partners.map((partner, index) => (
                        <ToolTip key={index} title={partner.name}>
                            <a 
                                href={partner.url} target="_blank" rel="noreferrer"
                                style={{ backgroundImage: `url(${partner.img})` }}
                                aria-label={partner.name}
                            >&nbsp;</a>
                        </ToolTip>
                    ))} 
                    
                </div>

            </div>

            <div className="wrapper">

                <div 
                    className="parent reverse"
                    style={{ background: 'var(--background)' }}
                >

                    <div 
                        style={{ 
                            backgroundImage: 'url(/images/map.png)',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            flex: 1 
                        }}
                    >

                        <strong style={{ fontSize: '14px', color: 'var(--icon-color)' }}>
                            Marine Graphics Ltd. Part.
                        </strong>

                        <br />
                        <br />

                        <small>
                            162/60 Moo 10
                            <br />
                            T. Chalong, A. Muang
                            <br />
                            Phuket 83130
                            <br />
                            Thailand
                            <br />
                            <br />
                            <ToolTip title="English">
                                <img 
                                    src="https://flagcdn.com/gb.svg" 
                                    alt="uk" 
                                    width="20"
                                    height="16"
                                    style={{ width: 20, marginRight: 8 }}
                                    loading="lazy"
                                /> 	
                            </ToolTip>

                            <span>+66 (0) 8 6267 2369</span>   

                            <br />
                            <ToolTip title="Thai">
                                <img 
                                    src="https://flagcdn.com/th.svg" 
                                    alt="thailand" 
                                    width="20"
                                    height="16"
                                    style={{ width: 20, marginRight: 8 }}
                                    loading="lazy"
                                /> 	
                            </ToolTip>

                            <span>+66 (0) 9 2496 2287</span>                         

                        </small>

                    </div>

                    <div style={{ flex: 1 }}>                       

                        <ul className="footer-links">
                            <li>
                                <Link to="/privacy" className="d-flex align-items-center">
                                    Privacy policy&nbsp;
                                    <button className="icon-button" aria-label="Privacy">
                                        <LockIcon />
                                    </button>
                                </Link>
                            </li>
                            <li>
                                <Link to="/terms" className="d-flex align-items-center">
                                    Terms of use&nbsp;
                                    <button className="icon-button" aria-label="Terms">
                                        <GavelIcon />
                                    </button>
                                </Link>
                            </li>
                            <li>
                                <Link to="/faqs" className="d-flex align-items-center">
                                    
                                    Frequently asked questions&nbsp;
                                    <button className="icon-button" aria-label="FAQs">
                                        <QuestionMarkIcon />
                                    </button>
                                </Link>
                            </li>
                        </ul>

                    </div>                    

                </div>  

            </div>  

            <div className="background-panel">
                
                <small style={{ lineHeight: '2rem' }}>
                    Copyright 
                    &copy;
                    &nbsp;
                    <span style={{ color: 'var(--icon-color)' }}>{process.env.REACT_APP_SITE_NAME}</span> 
                    &nbsp;
                    {new Date().getFullYear()}
                    . All Rights Reserved.
                </small>

            </div>
            
            <CookieConsent
                debug={
                    process.env.REACT_APP_NODE_ENV === 'DEVELOPMENT' 
                        ? true 
                        : false
                }
                buttonStyle={{ 
                    textTransform:'none', 
                    backgroundColor: "var(--primary-color)", 
                    color: "white", 
                    padding: '20px' 
                }}
                buttonText="Ok, got it"
                ariaAcceptLabel="Ok, got it"
                expires={365}
                style={{ 
                    background: "var(--background)", 
                    color: '#303030',
                    boxShadow: '0px -1px 15px 10px rgba(0,0,0,0.1'
                }}
            >
                <p>This site uses cookies. See our&nbsp;
                    <Link style={{ textDecoration: "underline" }} to="/privacy">Privacy Policy</Link> for details.

                </p>                    
            </CookieConsent> 

        </footer>

        <ScrollToTop 
            smooth 
            top={500}
            style={{ background: 'none', boxShadow: 'none', right: 0, top: '50%', transform: 'translateY(-50%)' }} 
            component={<ArrowCircleUpIcon style={{ fontSize: '3rem' }} />} 
        />
        </>
    )
    
}

export default Footer
